import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService, LocalizationService } from '../services';
import { invitePageRegExp } from '../utilities';
import { SystemPages } from '../enums/routerPaths.enum';
import { Locale } from '../models';
import { Localization } from '../classes';

@Injectable({
  providedIn: 'root'
})
export class PublicGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    private localizationService: LocalizationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let canActive: boolean = true;
    const isUserLoggedIn: boolean = this.authService.getIsUserLoggedIn();

    if (isUserLoggedIn) {
      if (PublicGuard.isResetPasswordLink(route)) {
        void this.router.navigate([SystemPages.USER_PROFILE], { queryParams: route.queryParams });
        return false;
      }

      canActive = PublicGuard.isAvailableToAll(state);
      const lang: Locale = this.localizationService.getCurrentLocale(
        this.authService.getIsUserLoggedIn()
      );

      if (!Localization.doesRouteIncludeLang(route, lang)) {
        // If lang param is not present, add it to the route
        const fullPath: string = Localization.getFullPathByState(state, lang);

        void this.router.navigateByUrl(fullPath);
      } else if (!canActive && state.url.indexOf(`/${lang}`) === 0) {
        const fullPath: string = Localization.getFullPath(SystemPages.SEARCH, lang);
        this.router.navigateByUrl(fullPath).then(() => {});
      }
    }

    return canActive;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(next, state);
  }

  private static isAvailableToAll(state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this.isInvitePage(url);
  }

  private static isInvitePage(url: string): boolean {
    return !!url.match(invitePageRegExp);
  }

  private static isResetPasswordLink(route: ActivatedRouteSnapshot): boolean {
    return route.queryParamMap.has('password_key');
  }
}
