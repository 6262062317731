import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { Observable } from 'rxjs';
import {
  CleverStaffAccountCredentials,
  CleverStaffProfileDupclication,
  Integration
} from '../../models';

@Injectable({ providedIn: 'root' })
export class IntegrationService {
  constructor(private restService: RestService) {}

  addOrEditCleverStaffAccount(credentials: CleverStaffAccountCredentials): Observable<undefined> {
    return this.restService.Integration().addOrEditCleverStaffAccount(credentials);
  }

  deleteCleverStaffAccount(): Observable<undefined> {
    return this.restService.Integration().deleteCleverStaffAccount();
  }

  getProfileCleverStaffByDocId(docId: string): Observable<CleverStaffProfileDupclication[]> {
    return this.restService.Integration().getCandidateCsListByDockId(docId);
  }

  getIntegrations(): Observable<Integration[]> {
    return this.restService.Integration().getIntegrations();
  }
}
