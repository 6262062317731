import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Folder, ModalInfo, MoveCandidatesToFolderModalData } from '../../../../../shared/models';

@Component({
  selector: 'app-candidates-to-folder-action-modal',
  templateUrl: './candidates-to-folder-action-modal.component.html',
  styleUrls: ['./candidates-to-folder-action-modal.component.scss']
})
export class CandidatesToFolderActionModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<CandidatesToFolderActionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MoveCandidatesToFolderModalData,
    private fb: UntypedFormBuilder
  ) {}

  info: ModalInfo = {
    type: 'simple-modal',
    extraCssClass: 'move-candidates-to-folder-modal',
    closeModalBtnId: 'close-move-candidates-to-folder-m',
    static: {
      height: true,
      title: true
    },
    header: {
      title: 'FOLDERS.FOLDER_PAGE.CANDIDATE_ACTIONS.MODALS.MOVE.TITLE',
      textContent: true,
      withForm: true
    },
    actions: {
      cancel: {
        id: 'm-cancel-move-candidates-to-folder--btn'
      },
      submit: {
        id: 'm-move-candidates-to-folder--btn',
        type: 'done',
        extraClass: 'bright'
      }
    }
  };

  form: UntypedFormArray;
  selectedFolderId: string;

  private unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.setRestModalInfoOnInit();
    this.initForm();
    this.handleFormValueChanges();
  }

  closeModal(folder: Folder = null): void {
    this.dialogRef.close(folder);
  }

  onSubmit(): void {
    if (this.form?.value?.length) {
      const chosenFolder: Folder = this.form.value.find((folder: Folder) => folder.candidateAdded);

      if (chosenFolder) {
        this.closeModal(chosenFolder);
      }
    }
  }

  choseFolder(folder: Folder): void {
    if (this.form?.value?.length) {
      this.form.value.forEach((folderItem: Folder, index: number) => {
        const condition: boolean = folderItem.id === folder.id;

        if (condition) {
          this.handleChoseFolderItemAtTarget(index, folder.id);
        } else {
          this.handleChoseFolderItemAtNotTarget(folderItem, index);
        }
      });
    }
  }

  private handleChoseFolderItemAtTarget(itemIndex: number, selectedFolderId: string): void {
    this.form.value[itemIndex].candidateAdded = !this.form.value[itemIndex].candidateAdded;

    if (this.form.value[itemIndex].candidateAdded) {
      this.selectedFolderId = selectedFolderId;

      this.form.value[itemIndex].candidatesCount += this.data.candidateLength || 0;
    } else {
      this.selectedFolderId = null;

      this.form.value[itemIndex].candidatesCount -= this.data.candidateLength || 0;
    }

    this.setSubmitBtnDisabledCondition();
  }

  private handleChoseFolderItemAtNotTarget(folderItem: Folder, itemIndex: number): void {
    this.form.value[itemIndex].candidateAdded = false;
    this.form.value[itemIndex].candidatesCount = folderItem.tempCandidatesCount;
  }

  private initForm(): void {
    this.form = this.fb.array([]);

    if (this.data.folders?.length) {
      const folders: Folder[] = [...this.data.folders];

      folders.forEach((folder: Folder) => {
        folder.tempCandidatesCount = folder.candidatesCount;

        this.form.push(this.fb.control({ ...folder }));
      });
    }
  }

  private setRestModalInfoOnInit(): void {
    this.info.static.text = this.data.type === 'move' || this.data.type === 'duplicate';
  }

  private setSubmitBtnDisabledCondition(): void {
    this.info.actions.submit.disabled = this.form.invalid || !this.selectedFolderId;
  }

  private handleFormValueChanges(): void {
    this.setSubmitBtnDisabledCondition();

    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.setSubmitBtnDisabledCondition();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
