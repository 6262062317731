import { GooglePlace, Locale } from '../models';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { systemLocales } from '../config';

export class Localization {
  static getRootPath(lang: Locale): string {
    return `/${lang}`;
  }

  static getFullPath(path: string, lang: Locale): string {
    // path format: '/path', examples: LandingPaths, SystemPages enums
    return `${lang}${path}`;
  }

  static getFullPathByState(state: RouterStateSnapshot, lang: Locale): string {
    const handledPath: string = state.url
      .split('/')
      .filter((part) => part !== '')
      .join('/');

    return `${lang}/${handledPath}`;
  }

  static doesRouteIncludeLang(route: ActivatedRouteSnapshot, lang: Locale): boolean {
    return route.params['lang'] === lang;
  }

  static getGooglePlaceName(googlePlace: GooglePlace, systemLocale: Locale): string {
    const fieldForCity: string =
      systemLocale === 'ua' ? 'cityUa' : systemLocale === 'en' ? 'cityEn' : 'cityEn';
    const fieldForCountry: string =
      systemLocale === 'ua' ? 'countryUa' : systemLocale === 'en' ? 'countryEn' : 'countryEn';
    const fieldForArea: string =
      systemLocale === 'ua' ? 'areaUa' : systemLocale === 'en' ? 'areaEn' : 'areaEn';

    const city: string = googlePlace[fieldForCity] ? googlePlace[fieldForCity] : '';
    const country: string = googlePlace[fieldForCountry] ? googlePlace[fieldForCountry] : '';
    const area: string = googlePlace[fieldForArea] ? googlePlace[fieldForArea] : '';

    const cityAreaSeparator: string = city && area ? ', ' : '';
    const areaCountrySeparator: string = (area || city) && country ? ', ' : '';

    return `${city}${cityAreaSeparator}${area}${areaCountrySeparator}${country}`;
  }
}
