import { HttpErrorResponse, HttpRequest } from '@angular/common/http';

import { HeaderTypeEnum, StatusCodes } from '../enums';
import { environment } from '../../../environments/environment';
import { ApiError } from '../models';
import { redirectLinkRegExp } from '../utilities';

interface RequestInfo {
  path: string;
  regExp?: RegExp;
  errorCode: StatusCodes;
}

export class TokenUtilities {
  private static requestListWithoutAuthHeaders = [
    '/user/login',
    '/user/register',
    '/user/add/',
    '/user/requestPasswordChange/',
    '/user/submitPasswordChange/',
    '/user/test',
    '/user/saveUtmAndGa',
    '/service/price',
    '/service/v2/price'
  ];

  private static requestInfoListWithoutErrorMessages: RequestInfo[] = [
    {
      path: '/profile/get',
      errorCode: StatusCodes.TOO_MANY_REQUESTS
    },
    {
      path: '/profile/openProfileContacts',
      errorCode: StatusCodes.TOO_MANY_REQUESTS
    },
    {
      path: '/profile/searchProfileContacts',
      errorCode: StatusCodes.TOO_MANY_REQUESTS
    },
    {
      path: '/profile/getProfileLinks',
      errorCode: StatusCodes.TOO_MANY_REQUESTS
    },
    {
      path: '/profile/search',
      errorCode: StatusCodes.TOO_MANY_REQUESTS
    },
    {
      path: '/redirect/link/',
      regExp: redirectLinkRegExp,
      errorCode: StatusCodes.TOO_MANY_REQUESTS
    },
    {
      path: '/user/generateNewApiKey',
      errorCode: StatusCodes.NO_PERMISSION
    },
    {
      path: '/integration/addOrEditCleverStaffAccount',
      // It do not make any sence, but that's what backend is returning when sending wrong credentials
      errorCode: StatusCodes.INTERNAL_SERVER_ERROR
    }
  ];

  static getNotShowMessageInfoList(): ApiError[] {
    return [
      {
        httpStatusCode: 400,
        message: 'YOUR ACCOUNT HAS BEEN BLOCKED. THE FUNCTION IS NOT AVAILABLE'
      }
    ];
  }

  static notShowErrorRequestList(): string[] {
    const backEndUrl: string = environment?.backEndUrl;

    return backEndUrl && [`${backEndUrl}/payment/calculateDiscount`];
  }

  static getHandledHttpError(error: HttpErrorResponse): HttpErrorResponse {
    let errorCopy: HttpErrorResponse = { ...error };

    if (typeof errorCopy?.error === 'string') {
      errorCopy = { ...errorCopy, error: JSON.parse(errorCopy.error) };
    }

    return errorCopy;
  }

  static getRequestWithAuthHeader(token: string, request: HttpRequest<any>): HttpRequest<any> {
    if (token) {
      return request.clone({
        setHeaders: {
          [HeaderTypeEnum.AUTHORIZATION]: `Bearer ${token}`
        }
      });
    }

    return null;
  }

  static isItRequestWithoutAuthHeader(request: HttpRequest<any>): boolean {
    const isRequestWithoutAuthToken: boolean =
      TokenUtilities.isItSystemRequestWithoutAuthHeader(request);

    return isRequestWithoutAuthToken || !request?.url?.includes(environment.backEndUrl);
  }

  static isItRefreshingRequest(request: HttpRequest<any>): boolean {
    const refreshTokenRequestUrl = `${environment?.backEndUrl}/user/refresh`;

    return request?.url?.includes(refreshTokenRequestUrl);
  }

  static is401or403Error(error: HttpErrorResponse): boolean {
    if (error?.error?.httpStatusCode) {
      return error?.error?.httpStatusCode === 401 || error?.error?.httpStatusCode === 403;
    }

    return error?.status === 401 || error?.status === 403;
  }

  private static getRequestListWithoutAuthHeaders(): string[] {
    const backEndUrl: string = environment?.backEndUrl;

    return TokenUtilities.requestListWithoutAuthHeaders.map((request) => `${backEndUrl}${request}`);
  }

  private static getRequestListWithoutErrorMessages(): RequestInfo[] {
    const backEndUrl: string = environment?.backEndUrl;

    return TokenUtilities.requestInfoListWithoutErrorMessages.map((info: RequestInfo) => {
      const { path } = info;

      return { ...info, path: `${backEndUrl}${path}` };
    });
  }

  private static isItSystemRequestWithoutAuthHeader(request: HttpRequest<any>): boolean {
    const requestList: string[] = TokenUtilities.getRequestListWithoutAuthHeaders();

    return requestList.some((pathItem: string) => request?.url?.includes(pathItem));
  }

  static isItRequestListWithoutErrorMessages(
    requestUrl: string,
    requestErrorCode: number
  ): boolean {
    const requestList: RequestInfo[] = TokenUtilities.getRequestListWithoutErrorMessages();

    return requestList.some((item: RequestInfo) => {
      const { path, regExp, errorCode } = item;

      if (regExp) {
        return requestUrl.match(regExp) && requestErrorCode === errorCode;
      }

      return requestUrl.split('?')[0] === path && requestErrorCode === errorCode;
    });
  }
}
