import { Injectable } from '@angular/core';
import { Location, PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(
    private location: Location,
    private platformLocation: PlatformLocation
  ) {}

  getOrigin(): string {
    return (this.platformLocation as any)._location.origin;
  }

  back(): void {
    this.location.back();
  }

  replaceState(path: string, query: string = ''): void {
    this.location.replaceState(path, query);
  }

  replaceQuery(query = ''): void {
    const path = this.location.path().split('?')[0];
    this.replaceState(path, query);
  }

  clearQueriesWithoutNavigation(): void {
    this.replaceQuery('');
  }
}
