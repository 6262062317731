import {
  FacetResponseData,
  FilterCheckboxItem,
  QueryLabelInfo,
  SpecificFacetType
} from '../../../../shared/models';
import { FilterValueWrapperInfo } from '../../models/search-filter';

export class CheckboxFilterQueryItem {
  constructor(public data: FilterCheckboxItem) {}

  private readonly valueWrapper = `'`;

  // actions:

  valueChanged(): void {
    this.data.checked = !this.data.checked;
  }

  // data handle:

  doesHaveAnyValue(): boolean {
    return this.data.checked;
  }

  getContentString(wrapperInfo: FilterValueWrapperInfo): string {
    const { value, checked } = this.data;

    if (value && checked) {
      const withWrapper: boolean = wrapperInfo?.withWrapper;
      const valueWrapper = withWrapper ? this.valueWrapper : '';

      return `${valueWrapper}${value}${valueWrapper}`;
    }

    return '';
  }

  setFacetByResponse(facetResponse: FacetResponseData): void {
    if (facetResponse) {
      const facetType: SpecificFacetType = this.data.specificFacetType;

      const facetNumber = facetResponse[facetType];
      if (typeof facetNumber === 'number') {
        this.data.facet = facetResponse[facetType];
      }
    }
  }

  isSearchItemTheValue(searchTextItem: string, wrapperInfo: FilterValueWrapperInfo): boolean {
    const withWrapper: boolean = wrapperInfo?.withWrapper;
    const handledSearchItem: string = withWrapper
      ? searchTextItem.trim().slice(1, -1)
      : searchTextItem;

    return this.data.value === handledSearchItem;
  }

  getLabelInfo(): QueryLabelInfo {
    if (this.data.checked) {
      const label: string = this.data.labelFull || this.data.label;

      return { value: label };
    }

    return null;
  }
}
