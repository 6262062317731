import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestService } from './rest.service';
import { catchError, map } from 'rxjs/operators';
import { ApiResponseSingle } from '../../models';
import {
  CleverStaffExportSettingsAPIPayload,
  CSVExportRecord,
  CSVExportState,
  CSVExportStatus,
  ExportProfilesRequestPayload,
  ExportSettings,
  ProfileExportSettingAPIPayload
} from '../../models/export';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor(
    private restService: RestService,
    private fileService: FileService
  ) {}

  downloadCSVFile(id: string, name: string): void {
    this.restService
      .Export()
      .getCVSFile(id)
      .pipe(
        catchError(() => of(false)),
        map((blob: Blob) => {
          const filename = `${name}.csv`;
          this.fileService.downloadBlobAsFile(blob, filename);

          return true;
        })
      )
      .subscribe();
  }

  getExportList(status?: CSVExportStatus): Observable<CSVExportRecord[]> {
    const query = status ? `?status=${status}` : '';

    return this.restService
      .Export()
      .getExportList(query)
      .pipe(
        map((data: ApiResponseSingle<CSVExportRecord[]>) => {
          return RestService.getData(data);
        })
      );
  }

  createProfileExport(
    searchQuery: string,
    payload: ExportProfilesRequestPayload
  ): Observable<CSVExportRecord> {
    return this.restService
      .Export()
      .createProfileExport(searchQuery, payload)
      .pipe(
        map((responce: ApiResponseSingle<CSVExportRecord>) => {
          return RestService.getData(responce);
        })
      );
  }

  createCleverStaffProfileExport(
    searchQuery: string,
    payload: ExportProfilesRequestPayload
  ): Observable<CSVExportRecord> {
    return this.restService
      .Export()
      .createCleverStaffProfileExport(searchQuery, payload)
      .pipe(
        map((responce: ApiResponseSingle<CSVExportRecord>) => {
          return RestService.getData(responce);
        })
      );
  }

  getExportState(id: string): Observable<CSVExportState> {
    return this.restService
      .Export()
      .getExportState(id)
      .pipe(
        map((responce: ApiResponseSingle<CSVExportState>) => {
          return RestService.getData(responce);
        })
      );
  }

  getSettings(): Observable<ExportSettings> {
    return this.restService
      .Export()
      .getExportSettings()
      .pipe(
        map((responce: ApiResponseSingle<ExportSettings>) => {
          return RestService.getData(responce);
        })
      );
  }

  getCleverStaffSettings(): Observable<CleverStaffExportSettingsAPIPayload> {
    return this.restService
      .Export()
      .getCleverStaffExportSettings()
      .pipe(
        map((responce: ApiResponseSingle<CleverStaffExportSettingsAPIPayload>) => {
          return RestService.getData(responce);
        })
      );
  }

  changeProfileSetting(settings: ProfileExportSettingAPIPayload): Observable<any> {
    const updateTimestamp = new Date().toISOString();

    return this.restService.Export().changeProfileExportSetting({
      ...settings,
      updateTimestamp
    });
  }

  changeCleverStaffSettings(settings: CleverStaffExportSettingsAPIPayload): Observable<any> {
    const updateTimestamp = new Date().toISOString();

    return this.restService.Export().changeCleverStaffExportSettings({
      ...settings,
      updateTimestamp
    });
  }
}
