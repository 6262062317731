import {
  ActionTypeNotification,
  AddProfileInFoldersRequestData,
  CandidateFolder,
  CandidateToFolderNotificationInfo,
  Folder,
  FolderActionTypeNotificationInfo,
  FolderFilter,
  FolderFormValue,
  FolderNavigateState,
  FolderNotificationInterpolateParams,
  FolderParams,
  FolderQueryParams,
  FoldersStatusListInfo,
  FolderStatus,
  SelectedCandidatesNotificationInfo,
  StatusActionTypeNotificationInfo
} from '../../../../shared/models';
import { FolderPage, FolderPageQueries, NotificationTypeEnum } from '../../../../shared/enums';
import { FolderAccessType, FolderStateType, StatusesInfoFilterKey } from '../../../../shared/types';
import { NavigationExtras, Params } from '@angular/router';
import { FolderQueryParamsConstructor } from '../../../../shared/classes';

export class FolderHandlerUtilities {
  constructor() {}

  // use in handleNotifications:
  static readonly statusesNotificationInfo: StatusActionTypeNotificationInfo = {
    init: null,
    create: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.STATUS_CREATED',
      hasInterpolateParams: true
    },
    remove: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.STATUS_DELETED',
      hasInterpolateParams: true
    },
    edit: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.STATUS_EDITED',
      hasInterpolateParams: false
    },
    changeColor: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.STATUS_COLOR_CHANGED',
      hasInterpolateParams: false
    },
    setDefault: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.STATUS_CHANGED_TO_DEFAULT',
      hasInterpolateParams: true
    },
    offDefault: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.STATUS_REMOVED_FROM_DEFAULT',
      hasInterpolateParams: true
    }
  };

  // use in handleNotifications:
  static readonly foldersNotificationInfo: FolderActionTypeNotificationInfo = {
    init: null,
    create: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.FOLDER_CREATED',
      hasInterpolateParams: true
    },
    remove: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.FOLDER_DELETED',
      hasInterpolateParams: true
    },
    edit: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.FOLDER_EDITED',
      hasInterpolateParams: false
    },
    archive: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.FOLDER_ARCHIVED',
      hasInterpolateParams: true
    },
    unarchive: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.FOLDER_UNARCHIVED',
      hasInterpolateParams: true
    }
  };

  // use in handleNotifications:
  static readonly candidateToFolderNotificationInfo: CandidateToFolderNotificationInfo = {
    addToFolder: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.CANDIDATE_ADDED_TO_FOLDER',
      hasInterpolateParams: true
    },
    addToCreatedFolder: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.CANDIDATE_ADDED_TO_CREATED_FOLDER',
      hasInterpolateParams: true
    },
    addToFolders: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.CANDIDATE_ADDED_TO_FOLDERS',
      hasInterpolateParams: false
    },
    removeFromFolder: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.CANDIDATE_REMOVED_FROM_FOLDER',
      hasInterpolateParams: true
    },
    removeFromFolders: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.CANDIDATE_REMOVED_FROM_FOLDERS',
      hasInterpolateParams: false
    },
    addAndRemoveFromFolders: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.CANDIDATE_ADDED_AND_REMOVED_FROM_FOLDERS',
      hasInterpolateParams: false
    }
  };

  // use in handleNotifications:
  static readonly selectedCandidatesNotificationInfo: SelectedCandidatesNotificationInfo = {
    remove: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.CANDIDATES_REMOVED_FROM_FOLDER',
      hasInterpolateParams: true,
      hasMultiple: true
    },
    changeStatus: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.CANDIDATES_FOLDER_STATUS_CHANGED',
      hasInterpolateParams: true,
      hasMultiple: true
    },
    duplicate: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.CANDIDATES_FOLDER_DUPLICATED',
      hasInterpolateParams: true,
      hasMultiple: true
    },
    move: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.CANDIDATES_FOLDER_MOVED',
      hasInterpolateParams: true,
      hasMultiple: true
    },
    duplicateToCreated: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.CANDIDATES_CREATED_FOLDER_DUPLICATED',
      hasInterpolateParams: true,
      hasMultiple: true
    },
    moveToCreated: {
      type: NotificationTypeEnum.SUCCESS,
      message: 'NOTIFICATION.CANDIDATES_CREATED_FOLDER_MOVED',
      hasInterpolateParams: true,
      hasMultiple: true
    }
  };

  static getDefaultFolderFilterInfo(): FolderFilter {
    return {
      state: {
        opened: true,
        archived: false
      },
      access: {
        private: true,
        team: false
      }
    };
  }

  static getNotificationMessage(
    notificationInfo: ActionTypeNotification,
    interpolateParams: FolderNotificationInterpolateParams
  ): string {
    let message: string = notificationInfo?.message;

    if (notificationInfo?.hasMultiple && message) {
      const { name, multipleInfo } = interpolateParams;

      if (multipleInfo) {
        message = `${message}.MULTIPLE`;
      } else if (name) {
        message = `${message}.SINGLE`;
      }
    }

    return message;
  }

  static handleFolderQueryParams(queryParams: FolderQueryParams): void {
    if (!queryParams.page) {
      queryParams.page = FolderPage.DEFAULT_PAGE;
    }

    if (!queryParams.count) {
      queryParams.count = FolderPage.PROFILES_PAGE_SIZE;
    }
  }

  static getFilteredFoldersResult(folder: Folder, folderFilterInfo: FolderFilter): boolean {
    const filterConditionByState: boolean = FolderHandlerUtilities.getFolderFilterConditionByState(
      folderFilterInfo,
      folder
    );
    const filterConditionByAccess: boolean =
      FolderHandlerUtilities.getFolderFilterConditionByAccess(folderFilterInfo, folder);

    return filterConditionByState && filterConditionByAccess;
  }

  static getFolderFilterConditionByState(formInfo: FolderFilter, folder: Folder): boolean {
    return (
      (formInfo?.state?.archived && folder.archived) ||
      (formInfo?.state?.opened && !folder.archived)
    );
  }

  static getFolderFilterConditionByAccess(formInfo: FolderFilter, folder: Folder): boolean {
    return (
      (formInfo?.access?.private && folder.privateAccess) ||
      (formInfo?.access?.team && !folder.privateAccess)
    );
  }

  static generateNewFolderFromFormValue(
    value: FolderFormValue,
    folderForEdit: Folder = {}
  ): Folder {
    const { name, description } = value;
    const privateAccess: boolean = value.accessType === 'private';
    const statusIds: string[] = FolderHandlerUtilities.getDefaultStatusIds(
      value.selectedStatuses,
      !!folderForEdit?.name
    );

    if (!folderForEdit) {
      folderForEdit = {};
    }

    return {
      ...folderForEdit,
      statuses: null,
      name,
      description,
      privateAccess,
      statusIds
    };
  }

  private static getDefaultStatusIds(
    statuses: FolderStatus[],
    isForEdit: boolean = false
  ): string[] {
    const defaultStatusIds: string[] = statuses
      ?.filter((status: FolderStatus) => (isForEdit ? status.active : status.defaultStatus))
      ?.map((status: FolderStatus) => status.id);

    return defaultStatusIds || [];
  }

  static getNotSortedStatusesListInfo(
    statuses: FolderStatus[],
    key: StatusesInfoFilterKey = 'defaultStatus'
  ): FoldersStatusListInfo {
    const filteredStatuses: FolderStatus[] = [];
    const restStatuses: FolderStatus[] = [];

    if (statuses?.length) {
      statuses?.forEach((status: FolderStatus) => {
        if (status[key]) {
          filteredStatuses.push(status);
        } else {
          restStatuses.push(status);
        }
      });
    }

    return {
      filteredStatuses,
      restStatuses
    };
  }

  static getNavigationExtrasForFolder(params: FolderParams): NavigationExtras {
    const queryParams: FolderQueryParams = FolderQueryParamsConstructor.toInstance(params);
    queryParams.page += 1;

    return { queryParams };
  }

  static getAddProfileInFoldersRequestData(
    docId: string,
    chosenFolders: Folder[]
  ): AddProfileInFoldersRequestData {
    if (chosenFolders?.length) {
      const profilesToAdd: CandidateFolder[] = chosenFolders.map((folder: Folder) => {
        return {
          folderId: folder.id,
          docId,
          statusId: folder.statusIds[0]
        };
      });

      return { profilesToAdd };
    }

    return null;
  }

  static getFolderParams(params: FolderParams): FolderParams {
    let folderRequestInfo: FolderParams = null;

    if (params?.id) {
      const queryParams: FolderQueryParams = FolderQueryParamsConstructor.toInstance(params);
      folderRequestInfo = { ...queryParams, id: params.id };

      if (!params.statusIds) {
        folderRequestInfo.statusIds = [];
      }

      if (!params.hasOwnProperty('page')) {
        folderRequestInfo.page = FolderPage.DEFAULT_PAGE as number;
      }

      if (!params.hasOwnProperty('count')) {
        folderRequestInfo.count = FolderPage.PROFILES_PAGE_SIZE as number;
      }
    }

    return folderRequestInfo;
  }

  static getQueryParamsForPreview(state: FolderStateType, access: FolderAccessType): Params {
    return {
      [FolderPageQueries.PREVIEW]: true,
      [FolderPageQueries.STATE]: state,
      [FolderPageQueries.ACCESS]: access
    };
  }

  static getSortedByOrderStatusesList(statuses: FolderStatus[] = []): FolderStatus[] {
    return statuses.sort((a: FolderStatus, b: FolderStatus) => {
      return a.position - b.position;
    });
  }
}
