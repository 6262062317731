  <div class="locked-checkbox">
    <svg
      width="16"
      height="16"
      class="icon locked-icon">
      <use xlink:href="#locked">
      </use>
    </svg>

    <span class="label-text">
      {{ data.label | translate }}
    </span>

    <ng-content></ng-content>
  </div>