import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FolderStatusColorType, FolderStatusIdsPositionInfo } from '../../../../shared/types';
import {
  ApiResponseSingle,
  FolderStatus,
  FolderStatusesListFromInfo
} from '../../../../shared/models';
import { RestService } from '../../../../shared/services';
import { FolderStatusesUtilities } from './folder-statuses.utilities';

@Injectable({
  providedIn: 'root'
})
export class FolderStatusesService {
  constructor(private translate: TranslateService) {}

  getStatusNameForTranslate(status: FolderStatus): string {
    return FolderStatusesUtilities.getStatusNameForTranslate(status);
  }

  getTranslatedStatusName(status: FolderStatus): string {
    return this.translate.instant(this.getStatusNameForTranslate(status));
  }

  handleStatuses(statuses: FolderStatus[]): void {
    if (statuses?.length) {
      statuses?.forEach((status: FolderStatus, index: number) => {
        statuses[index].nameForTranslate = this.getStatusNameForTranslate(status);

        if (!status?.color) {
          statuses[index].color = this.getRandomColorByStatusList(statuses);
        }
      });
    }
  }

  getRandomColorByStatusList(statusList: FolderStatus[]): FolderStatusColorType {
    const exceptionList: FolderStatusColorType[] = statusList?.length
      ? statusList.map((status: FolderStatus) => status.color)
      : [];

    return FolderStatusesUtilities.getRandomColorByExceptionList(exceptionList);
  }

  getStatusColorValue(colorName: string): string {
    return FolderStatusesUtilities.getStatusColorValue(colorName);
  }

  getStatusesFromApiResponseData(data: ApiResponseSingle<FolderStatus[]>): FolderStatus[] {
    const statuses: FolderStatus[] = RestService.getData(data);

    this.handleStatuses(statuses);

    return statuses;
  }

  // Folder page:

  getFolderStatusesListFromInfo({
    info,
    statusIds = [],
    filteredStatusIds = null
  }: FolderStatusesListFromInfo): FolderStatus[] {
    const statusIdsInfo: FolderStatusIdsPositionInfo = this.getStatusIdsPositionInfo(statusIds);

    return Object.values(info).map((status: FolderStatus) => {
      if (statusIdsInfo && statusIdsInfo[status.id]) {
        status.position = statusIdsInfo[status.id];
      }

      this.handleStatusItemActive(status, filteredStatusIds);

      return status;
    });
  }

  getStatusIdsPositionInfo(statusesIds: string[] = []): FolderStatusIdsPositionInfo {
    return statusesIds.reduce((acc: any, statusId: string, index: number) => {
      acc[statusId] = index + 1;

      return acc;
    }, {});
  }

  handleStatusItemActive(status: FolderStatus, filteredStatusIds: string[] = null): void {
    if (filteredStatusIds) {
      if (!filteredStatusIds?.length) {
        status.active = false;
      } else {
        status.active = filteredStatusIds.includes(status.id);
      }
    }
  }
}
