<fieldset
  [id]="wrapperId"
  class="checkbox-wrapper">
  <input
    type="checkbox"
    [id]="checkboxId"
    [checked]="data.checked"
    (change)="onValueChanged()"
    class="checkbox">

  <label
    [for]="checkboxId"
    class="field__label">
    <span class="field__text with-inner-facet">
      <span class="field__label-input">
        <svg
          width="10"
          height="8"
          class="icon">
          <use xlink:href="#check-icon">
          </use>
        </svg>
      </span>

      {{ data.label | translate }}

      <ng-content></ng-content>
    </span>
  </label>
</fieldset>
