import {
  Autocomplete,
  FacetResponseData,
  FilterCheckboxItem,
  HandledAutocompleteResponse,
  QueryLabelInfo,
  SearchQueryItemInfo
} from '../../../../shared/models';
import { searchQueryParamRegExp } from '../../../../shared/utilities';
import {
  ParseFiltersInfo,
  QueryStringComposeInfo,
  SearchFilterQueryData
} from '../../models/search-filter';
import { SearchFilterQueryItem } from './search-filter-query-item';
import { CheckboxFilterQueryItem } from './checkbox-filter-query-item';
import { QueryHelpers, SearchFilterType } from '../../data';

export class SearchFilterQuery {
  constructor(public data: SearchFilterQueryData) {
    this.handleDataOnInit();
  }

  // data handle:

  doesHaveAnyValue(): boolean {
    if (this.data.info.searchItemList?.length) {
      return (
        this.data.info.searchItemList.filter((item: SearchFilterQueryItem) =>
          item.data.value.trim()
        ).length > 0
      );
    } else if (this.data.info.filterItemList) {
      return this.data.info.filterItemList.some((item: CheckboxFilterQueryItem) =>
        item.doesHaveAnyValue()
      );
    } else if (this.data.info.rangesInfo) {
      return this.data.info.rangesInfo.doesHaveAnyValue();
    } else if (this.data.info.contactsInfo) {
      return this.data.info.contactsInfo.doesHaveAnyValue();
    } else if (this.data.info.reviewedFilterInfo) {
      return this.data.info.reviewedFilterInfo.doesHaveAnyValue();
    }

    return false;
  }

  getQueryString(separateExcluded: boolean = false, rawQuery: boolean = false): string | string[] {
    const content: string | string[] = this.getInputContent(separateExcluded, rawQuery);

    if (content) {
      if (Array.isArray(content)) {
        if (separateExcluded) {
          return content.map((item: string) => {
            const firstPart: string = this.getQueryStringFirstPart();

            return `${firstPart}${item}`;
          });
        }

        return content;
      }

      const firstPart: string = this.getQueryStringFirstPart();

      return `${firstPart}${content}`;
    }

    return '';
  }

  setDataFromSearchLine(
    queryItemInfo: SearchQueryItemInfo,
    parseFilterInfo: ParseFiltersInfo
  ): void {
    if (queryItemInfo) {
      const { autocompleteResponse, facetResponse } = parseFilterInfo;
      const { searchText } = queryItemInfo;

      if (this.data.info.searchItemList) {
        this.setSearchListFromSearchLine(searchText, autocompleteResponse);
      } else if (this.data.info.filterItemList) {
        this.setFilterListFromSearchLine(searchText);
      } else if (this.data.info.rangesInfo) {
        this.data.info.rangesInfo.setDataFromSearchLine(searchText);
      } else if (this.data.info.contactsInfo) {
        this.data.info.contactsInfo.setDataFromSearchLine(searchText, facetResponse);
      } else if (this.data.info.reviewedFilterInfo) {
        this.data.info.reviewedFilterInfo.setDataFromSearchLine(queryItemInfo);
      }
    }
  }

  setFacetByResponse(facetResponse: FacetResponseData): void {
    if (facetResponse) {
      if (this.data.info.filterItemList?.length) {
        this.data.info.filterItemList.forEach((item: CheckboxFilterQueryItem) => {
          item.setFacetByResponse(facetResponse);
        });
      } else if (this.data.info.contactsInfo) {
        this.data.info.contactsInfo.setFacets(facetResponse);
      }
    }
  }

  setItemValueByAutocomplete(autocomplete: Autocomplete): void {
    if (!this.data.info.searchItemList) {
      this.data.info.searchItemList = [];
    }

    if (autocomplete) {
      const queryItem: SearchFilterQueryItem = SearchFilterQueryItem.createItemFromAutocomplete(
        autocomplete,
        0
      );

      this.data.info.searchItemList.push(queryItem);
    }
  }

  getLabelValueList(): QueryLabelInfo[] {
    if (this.data.info.searchItemList?.length) {
      return this.data.info.searchItemList.map((item: SearchFilterQueryItem) =>
        item.getLabelInfo()
      );
    } else if (this.data.info.filterItemList) {
      return this.data.info.filterItemList.reduce(
        (acc: QueryLabelInfo[], item: CheckboxFilterQueryItem) => {
          const info: QueryLabelInfo = item.getLabelInfo();

          if (info) {
            acc.push(info);
          }

          return acc;
        },
        []
      );
    } else if (this.data.info.rangesInfo) {
      const info: QueryLabelInfo = this.data.info.rangesInfo.getLabelInfo();

      return info ? [info] : [];
    } else if (this.data.info.contactsInfo) {
      return this.data.info.contactsInfo.getLabelInfoList();
    } else if (this.data.info.reviewedFilterInfo) {
      const info: QueryLabelInfo = this.data.info.reviewedFilterInfo.getLabelInfo();

      return info ? [info] : [];
    }

    return [];
  }

  // Query handlers:

  getContentByParamIndex(content: string = '', index: number = 0): string {
    return content.replace(QueryHelpers.PARAM_INDEX, `${index}`);
  }

  getQueryStringComposeInfo(
    queryString: string | string[],
    paramLastIndex: number | undefined
  ): QueryStringComposeInfo {
    if (Array.isArray(queryString)) {
      const composedQueryString: string[] = (queryString as string[]).map((item: string) => {
        const itemComposeInfo: QueryStringComposeInfo = this.getQueryStringItemComposeInfo(
          item,
          paramLastIndex
        );
        paramLastIndex = itemComposeInfo.paramLastIndex;

        return itemComposeInfo.composedQueryString as string;
      });

      return {
        paramLastIndex,
        composedQueryString
      };
    } else {
      return this.getQueryStringItemComposeInfo(queryString, paramLastIndex);
    }
  }

  sortSortableCheckboxes(): void {
    if (this.data.checkedCheckboxOnTop) {
      this.data.info?.filterItemList?.sort((a, b) => +b.data.checked - +a.data.checked);
    }
  }

  private getQueryStringItemComposeInfo(
    queryString: string | string[],
    paramLastIndex: number | undefined
  ): QueryStringComposeInfo {
    paramLastIndex = paramLastIndex === undefined ? 0 : paramLastIndex + 1;
    const composedQueryString: string = this.getContentByParamIndex(
      queryString as string,
      paramLastIndex
    );

    return {
      paramLastIndex,
      composedQueryString
    };
  }

  static isExcludedQueryOnly(searchText: string): boolean {
    const paramList: string[] = searchText.match(searchQueryParamRegExp);

    if (paramList?.length) {
      const operator: string = SearchFilterQuery.parseOperatorFromSearchText(searchText);
      const match: boolean = operator.includes(QueryHelpers.MATCH_ALL_OPERATOR);
      const isAllQueriesExcluded: boolean = paramList.every((searchTextItem: string) => {
        return SearchFilterQueryItem.isItemExcluded(searchTextItem);
      });

      return isAllQueriesExcluded && (paramList.length === 1 || match);
    }

    return false;
  }

  static isIncludedQueryOnly(searchText: string): boolean {
    const paramList: string[] = searchText.match(searchQueryParamRegExp);

    if (paramList?.length) {
      return paramList.every((searchTextItem: string) => {
        return !SearchFilterQueryItem.isItemExcluded(searchTextItem);
      });
    }

    return false;
  }

  private setSearchListFromSearchLine(
    searchText: string,
    autocompleteResponse: HandledAutocompleteResponse
  ): void {
    if (!this.data.info.searchItemList) {
      this.data.info.searchItemList = [];
    }

    const autocompletes: Autocomplete[] = autocompleteResponse
      ? autocompleteResponse[this.data.autocompleteType]
      : null;
    const paramList: string[] = searchText.match(searchQueryParamRegExp);

    if (paramList?.length) {
      this.setMatchFromSearchLine(searchText);

      paramList.forEach((searchTextItem: string, index: number) => {
        const queryItem: SearchFilterQueryItem = SearchFilterQueryItem.createParsedItem(
          searchTextItem,
          index,
          autocompletes
        );

        this.data.info.searchItemList.push(queryItem);
      });
    }
  }

  private setMatchFromSearchLine(searchText: string): void {
    if (this.data.matcherInfo?.withMatcher) {
      const operator: string = SearchFilterQuery.parseOperatorFromSearchText(searchText);
      const parsedMatch: boolean = operator.includes(QueryHelpers.MATCH_ALL_OPERATOR);

      this.setMatch(parsedMatch);
    }
  }

  private setMatchFromFilterSearchLine(searchText: string): void {
    if (!this.data.matcherInfo?.withMatcher) return;

    const match = searchText.match(/(?:\s)(AND|OR)(?:\s)/);
    if (match && match[1] === 'AND') {
      this.setMatch(true);
    }
  }

  static parseSeparatorFromSearchText(searchText: string): string {
    const operator: string = SearchFilterQuery.parseOperatorFromSearchText(searchText);

    return SearchFilterQuery.getOperatorsSeparator(operator);
  }

  static parseOperatorFromSearchText(searchText: string): string {
    const operatorsList: string[] = [
      QueryHelpers.MATCH_ALL_OPERATOR,
      QueryHelpers.MATCH_ANY_OPERATOR
    ];
    const paramList: string[] = searchText.match(searchQueryParamRegExp);

    if (!(paramList?.length > 1)) {
      return '';
    }

    const operatorOffset = paramList[0].length;
    const searchPart = searchText.trim().slice(operatorOffset);
    const operator = searchPart.split(paramList[1])[0].trim();

    return operatorsList.includes(operator) ? operator : '';
  }

  static getOperatorsSeparator(operator: string): string {
    return `${QueryHelpers.SPACE}${operator}${QueryHelpers.SPACE}`;
  }

  private setFilterListFromSearchLine(searchText: string): void {
    this.setMatchFromFilterSearchLine(searchText);
    const paramsSeparator = this.getParamSeparator();
    const searchTextList: string[] = searchText.split(paramsSeparator);

    this.data.info.filterItemList.forEach((item: CheckboxFilterQueryItem) => {
      const paramValue: string = searchTextList.find((searchTextItem: string) => {
        return item.isSearchItemTheValue(searchTextItem, this.data.wrapperInfo);
      });

      if (paramValue) {
        item.valueChanged();
      }
    });

    this.sortSortableCheckboxes();
  }

  private getQueryStringFirstPart(): string {
    if (!this.data.notComposeParams && (this.data.queryParam || this.data.filterParam)) {
      const { queryParam, filterParam } = this.data;
      const firstPart = queryParam || filterParam;
      const secondPart = `${QueryHelpers.START_INDEX_WRAPPER}${QueryHelpers.PARAM_INDEX}${QueryHelpers.END_INDEX_WRAPPER}`;
      const thirdPart = QueryHelpers.SEMICOLON;

      return `${firstPart}${secondPart}${thirdPart}`;
    }

    return '';
  }

  private getParamSeparator(): string {
    const operator: string = this.getQueryStringOperator();

    return `${QueryHelpers.SPACE}${operator}${QueryHelpers.SPACE}`;
  }

  private getExcludedParamSeparator(): string {
    const operator: string = QueryHelpers.MATCH_ALL_OPERATOR;

    return `${QueryHelpers.SPACE}${operator}${QueryHelpers.SPACE}`;
  }

  private getQueryStringOperator(): string {
    const { matcherInfo } = this.data;

    if (matcherInfo?.withMatcher) {
      return matcherInfo.match ? QueryHelpers.MATCH_ALL_OPERATOR : QueryHelpers.MATCH_ANY_OPERATOR;
    }

    return QueryHelpers.MATCH_ANY_OPERATOR;
  }

  private getInputContent(
    separateExcluded: boolean = false,
    rawQuery: boolean = false
  ): string | string[] {
    const { searchItemList, filterItemList, rangesInfo, contactsInfo, reviewedFilterInfo } =
      this.data.info;

    if (searchItemList?.length) {
      return this.getInputsContentForQueries(searchItemList, separateExcluded, rawQuery);
    } else if (filterItemList?.length) {
      const paramsSeparator = this.getParamSeparator();

      return filterItemList
        .map((item: CheckboxFilterQueryItem) => item.getContentString(this.data.wrapperInfo))
        .filter((item: string) => !!item)
        .join(paramsSeparator);
    } else if (rangesInfo) {
      return rangesInfo.getContentString();
    } else if (contactsInfo) {
      return contactsInfo.getContentString();
    } else if (reviewedFilterInfo) {
      return reviewedFilterInfo.getContentString();
    }

    return '';
  }

  private getInputsContentForQueries(
    searchItemList: SearchFilterQueryItem[],
    separateExcluded: boolean = false,
    rawQuery: boolean = false
  ): string | string[] {
    const paramsSeparator = this.getParamSeparator();
    const excludedParamsSeparator = this.getExcludedParamSeparator();
    const queries: string[] = [];
    const excludedQueries: string[] = [];

    searchItemList.forEach((item: SearchFilterQueryItem) => {
      const query: string = item.getContentString(this.data.wrapperInfo, rawQuery);

      if (query) {
        if (item.data.excluded && separateExcluded) {
          excludedQueries.push(query);
        } else {
          queries.push(query);
        }
      }
    });

    const query: string = queries.join(paramsSeparator);
    const excludedQuery: string = excludedQueries.join(excludedParamsSeparator);

    if (separateExcluded) {
      return query && excludedQuery ? [query, excludedQuery] : query ? query : excludedQuery;
    }

    return query;
  }

  // actions:

  toggleMatch(): void {
    if (this.data.matcherInfo?.withMatcher) {
      this.data.matcherInfo.match = !this.data.matcherInfo.match;
    }
  }

  private setMatch(match: boolean): void {
    if (this.data.matcherInfo?.withMatcher) {
      this.data.matcherInfo.match = match;
    }
  }

  addQueryItem(withReset: boolean = false, value: string = ''): void {
    const newId: number = this.data.info.searchItemList?.length;
    const newQueryItem: SearchFilterQueryItem = SearchFilterQueryItem.createNew(newId, value);

    if (withReset) {
      this.resetInputItemsEdit();
    }

    this.data.info.searchItemList.push(newQueryItem);
  }

  resetInputItemsEdit(exceptionItem: SearchFilterQueryItem = null): void {
    if (this.data.info?.searchItemList) {
      this.filterEmptyItems();

      this.data.info.searchItemList.forEach((item: SearchFilterQueryItem) => {
        if (!exceptionItem || item.data.id !== exceptionItem.data.id) {
          item.resetEditMode();
        }
      });
    }
  }

  removeQueryItem(itemToRemove: SearchFilterQueryItem): void {
    this.data.info.searchItemList = this.data.info.searchItemList.filter(
      (item: SearchFilterQueryItem) => item.data.id !== itemToRemove.data.id
    );

    this.queryChildAction();
  }

  queryChildAction(): void {
    this.resetMatcherAfterAction();
  }

  private resetMatcherAfterAction(): void {
    const hasMultipleValues: boolean = this.hasMultipleValues();

    if (!hasMultipleValues && this.data.matcherInfo?.match) {
      this.setMatch(false);
    }
  }

  // ************************ searchItemList only *************************

  isItEditing(): boolean {
    // searchItemList only
    if (!this.data.info.searchItemList?.length) {
      return false;
    }

    return this.data.info.searchItemList.some((item: SearchFilterQueryItem) => {
      return item.data.editMode;
    });
  }

  hasMultipleValues(): boolean {
    // searchItemList only, included values only
    const minLength = 2;

    if (this.data.info.searchItemList?.length < minLength) {
      return false;
    }

    if (this.data.filterType === 'checkbox') {
      let checked = 0;

      for (const checkItem of this.data.info.filterItemList) {
        if (checkItem.data.checked) {
          checked++;
          if (checked >= minLength) {
            return true;
          }
        }
      }

      return false;
    }

    let nonExcludedCount = 0;
    for (const item of this.data.info.searchItemList) {
      if (item.data.value?.trim().length > 0 && !item.data.editMode && !item.data.excluded) {
        nonExcludedCount++;

        if (nonExcludedCount >= minLength) {
          return true;
        }
      }
    }

    return false;
  }

  hasAnyValue(): boolean {
    // searchItemList only
    if (!this.data.info.searchItemList?.length) {
      return false;
    }

    return this.data.info.searchItemList.some((item: SearchFilterQueryItem) => {
      return item.data.value;
    });
  }

  // *****************************************************************

  static getCheckboxItemList(list: FilterCheckboxItem[] = []): CheckboxFilterQueryItem[] {
    if (!list) {
      list = [];
    }

    const queryList: CheckboxFilterQueryItem[] = [];

    list.forEach((item: FilterCheckboxItem) => {
      const queryItem: CheckboxFilterQueryItem = new CheckboxFilterQueryItem(item);

      queryList.push(queryItem);
    });

    return queryList;
  }

  private handleDataOnInit(): void {
    if (!this.data.info) {
      this.data.info = {};
    }

    if (this.data.filterType === SearchFilterType.INPUT && !this.data.info.searchItemList) {
      this.data.info.searchItemList = [];
    }
  }

  private filterEmptyItems(): void {
    if (this.data.info.searchItemList) {
      this.data.info.searchItemList = this.data.info.searchItemList.filter(
        (item: SearchFilterQueryItem) => item.data.value?.trim().length
      );
    }
  }
}
