import {
  FacetResponseData,
  FacetType,
  SearchRequestInfo,
  SpecificFacetType
} from '../../../../../shared/models';
import { CategoryQueryParamEnum, FilterParam } from '../../../../../shared/enums';
import { searchQuerySeparator } from '../../../../../shared/utilities';

type OtherFilterAssets = {
  [key in FilterParam]?: string;
};

type OtherQueryAssets = {
  [key in CategoryQueryParamEnum]?: string;
};

export class Facets {
  static readonly FilterFacetAssets: OtherFilterAssets = {
    [FilterParam.withContacts]: 'contacts',
    [FilterParam.byLanguage]: 'languages',
    [FilterParam.byLevel]: 'levels',
    [FilterParam.byExperience]: 'levels',
    [FilterParam.byLastExperience]: 'levels',
    [FilterParam.byCompanySize]: 'companySize',
    [FilterParam.byIndustry]: 'industry',
    [FilterParam.byOpenToWork]: 'openToWork'
  };

  static readonly QueryFacetAssets: OtherQueryAssets = {
    [CategoryQueryParamEnum.byEducation]: 'languages',
    [CategoryQueryParamEnum.byCurrentCompany]: 'companySize',
    [CategoryQueryParamEnum.byCompany]: 'companySize'
  };

  static getFacetsFromRequestInfo(requestInfo: SearchRequestInfo): FacetType[] {
    const filterFacets: FacetType[] = Facets.getFilterFacets(requestInfo);
    const queryFacets: FacetType[] = Facets.getQueryFacets(requestInfo);

    return filterFacets.concat(queryFacets);
  }

  static getFacetFilterTypes(data: FacetResponseData): SpecificFacetType[] {
    return data ? Object.keys(data)?.map((key: SpecificFacetType) => key) : [];
  }

  private static getFilterFacets(requestInfo: SearchRequestInfo): FacetType[] {
    const { f } = requestInfo;
    let filterFacets: FacetType[] = [];

    if (f) {
      const filters: string[] = Array.isArray(f) ? f : [f];

      filterFacets = filters
        .map((filter: string) => {
          const separator: string = filter.match(searchQuerySeparator)[0];
          const filterParam: FilterParam = filter.split(separator)[0] as FilterParam;

          return Facets.FilterFacetAssets[filterParam] as FacetType;
        })
        .filter((item: FacetType) => !!item);
    }

    return filterFacets;
  }

  private static getQueryFacets(requestInfo: SearchRequestInfo): FacetType[] {
    const { q } = requestInfo;
    let queryFacets: FacetType[] = [];

    if (q) {
      const queries: string[] = Array.isArray(q) ? q : [q];

      queryFacets = queries
        .map((query: string) => {
          const separator: string = query.match(searchQuerySeparator)[0];
          const queryParam: CategoryQueryParamEnum = query.split(
            separator
          )[0] as CategoryQueryParamEnum;

          return Facets.QueryFacetAssets[queryParam] as FacetType;
        })
        .filter((item: FacetType) => !!item);
    }

    return queryFacets;
  }
}
