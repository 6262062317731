import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import * as moment from 'moment';

import { NameByLang, NumericNumberType, NumericType } from '../../types';
import { environment } from '../../../../environments/environment';
import { GooglePlace, Language, Locale, UserParameter } from '../../models';
import { StorageService } from '../global';
import { LocalizationData } from './localization.data';
import { Localization } from '../../classes';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  constructor(
    public translate: TranslateService,
    private storageService: StorageService
  ) {}

  private systemLocale: Locale;

  private systemLangList: Language[] = LocalizationData.systemLangList;
  private landingLangList: Language[] = LocalizationData.landingLangList;

  private updateSystemLocaleSubject = new BehaviorSubject<Locale>(null);
  private changeLandingLangSubject = new BehaviorSubject<Locale>(null);

  systemLocale$: Observable<Locale> = this.updateSystemLocaleSubject.asObservable();
  changeLandingLang$: Observable<Locale> = this.changeLandingLangSubject.asObservable();

  updateSystemLocale(locale: Locale): void {
    this.updateSystemLocaleSubject.next(locale);
  }

  getNumericByType(numericType: NumericType, numberType: NumericNumberType): string {
    if (numberType && numberType && LocalizationData.numericTypeList.includes(numericType)) {
      const numericId = 'NUMERIC';

      return `${numericId}.${numericType}.${numberType}`;
    }

    return '';
  }

  translateInstant(
    instant: string,
    interpolateParams: Object = {},
    showDefault: boolean = true
  ): string {
    let translate = '';

    if (!this.systemLocale) {
      this.setSystemLocale(null);

      translate = showDefault ? instant : '';
    } else if (instant && (showDefault || this.systemLocale)) {
      translate = this.translate.instant(instant, interpolateParams) || instant;
    }

    return translate;
  }

  getSystemLangList(): Language[] {
    const systemLocale: Locale = this.getSystemLocale();

    this.systemLangList.forEach((lang: Language, index: number) => {
      this.systemLangList[index].selected = lang?.locale === systemLocale;
    });

    return this.systemLangList;
  }

  getSystemSelectedLang(): Language {
    return this.systemLangList.find((lang: Language) => lang?.selected);
  }

  getNameByLang(): NameByLang {
    const systemLocale: Locale = this.getSystemLocale();
    let queryNameByLang: NameByLang = 'nameEn';

    if (systemLocale === 'ua') {
      queryNameByLang = 'nameUa';
    } else if (systemLocale === 'pl') {
      queryNameByLang = 'namePl';
    }

    return queryNameByLang;
  }

  getGooglePlaceName(googlePlace: GooglePlace): string {
    const systemLocale: Locale = this.getSystemLocale();

    return Localization.getGooglePlaceName(googlePlace, systemLocale);
  }

  getIsItSimpleNumericType(): boolean {
    const systemLocale: Locale = this.getSystemLocale();

    return systemLocale === 'en';
  }

  getSystemLocaleFromStorage(): Locale {
    // todo: return this method, temporary hide in order use only default lang:

    // const localeFromStorage: Locale =  this.storageService.getLocale();
    // const isLocaleFromStorageValid: boolean = systemLocales.some((locale: Locale) => localeFromStorage === locale);
    //
    // return isLocaleFromStorageValid ? localeFromStorage : null;

    return environment.defaultLocale;
  }

  getSystemLocale(): Locale {
    return (this.systemLocale || environment?.defaultLocale) as Locale;
  }

  getCurrentLocale(isUserAuthed: boolean = false): Locale {
    return isUserAuthed ? this.getSystemLocale() : this.getLandingSystemLocale();
  }

  getLocaleFromBrowser(): Locale {
    // todo: return this method, temporary hide in order use only default lang
    const browserLocale: string = this.translate.getBrowserLang();
    let locale = environment?.defaultLocale as Locale;

    if (browserLocale?.match(/en/i)) {
      locale = 'en';
    } else if (browserLocale === 'uk') {
      locale = 'ua';
    } else if (browserLocale === 'pl') {
      locale = 'pl';
    }

    return locale;
  }

  getTranslate(data: string): Observable<string> {
    return data ? this.translate.get(data) : of('');
  }

  createUserLangParameter(locale: Locale): UserParameter {
    return new UserParameter('language', locale);
  }

  getUserParameters(): UserParameter[] {
    const systemLocale = this.getSystemLocale();
    const userLangParameter: UserParameter = this.createUserLangParameter(systemLocale);

    return [userLangParameter];
  }

  changeLang(chosenLang: Language): void {
    this.systemLangList.forEach((langItem: Language, index: number) => {
      this.systemLangList[index].selected = langItem?.locale === chosenLang?.locale;
    });

    this.setSystemLocale(chosenLang?.locale);
  }

  setSystemLocale(locale: Locale = null, setToStorage: boolean = true): void {
    this.systemLocale =
      locale ||
      this.getSystemLocaleFromStorage() ||
      // this.getLocaleFromBrowser(); // todo: return other langs, temporary hide
      environment.defaultLocale;

    if (setToStorage) {
      this.setSystemLocaleToStorage(this.systemLocale);
    }

    this.translate
      .use(this.systemLocale)
      .pipe(take(1))
      .subscribe(() => {
        this.updateSystemLocale(this.systemLocale);
      });
  }

  setSystemLocaleToStorage(locale: Locale): void {
    this.storageService.setLocale(locale);
  }

  // Landing lang:

  getLandingSystemLocale(): Locale {
    return (this.systemLocale ||
      // this.getLocaleFromBrowser() // todo: return other langs, temporary hide
      environment.defaultLocale) as Locale;
  }

  getLandingLangList(): Language[] {
    const systemLocale: Locale = this.getLandingSystemLocale();

    this.landingLangList.forEach((lang: Language, index: number) => {
      this.landingLangList[index].selected = lang?.locale === systemLocale;
    });

    return this.landingLangList;
  }

  getLandingSelectedLang(): Language {
    return this.landingLangList.find((lang: Language) => lang?.selected);
  }

  changeLandingLang(locale: Locale): void {
    this.landingLangList.forEach((lang: Language, index: number) => {
      this.landingLangList[index].selected = lang.locale === locale;
    });

    this.setSystemLocale(locale);
    this.changeLandingLangSubject.next(locale);
  }

  getDateLocale(
    value: string,
    dateFormat: string,
    dateFormatEn: string = null,
    parseZone: boolean = true
  ): string {
    if (!value) {
      return '';
    }

    let locale: string = this.getSystemLocale();
    const resultDateFormat: string = dateFormatEn && locale === 'en' ? dateFormatEn : dateFormat;

    if (locale === 'ua') {
      locale = 'uk';
    }

    moment.locale(locale);

    const dateLocale = parseZone ? moment(new Date(value)) : moment.utc(value);

    return dateLocale.format(resultDateFormat);
  }

  private static getNumberTypeOtherFromFive(numberOfNumeric: number): NumericNumberType {
    let numberType: NumericNumberType;

    const i: number = numberOfNumeric % 10;

    if (i === 1) {
      numberType = 1;
    } else if (i >= 2 && i <= 4) {
      numberType = 4;
    } else {
      numberType = 5;
    }

    return numberType;
  }

  getCurrentNumeric(numberOfNumeric: number = 0, numericType: NumericType): string {
    const isSimpleType: boolean = this.getIsItSimpleNumericType();

    return this.getNumeralWithEnding(numberOfNumeric, numericType, isSimpleType);
  }

  private static getNumberTypeIfNotSimple(numberOfNumeric: number): NumericNumberType {
    let numberType: NumericNumberType;

    numberOfNumeric = numberOfNumeric % 100;

    if (numberOfNumeric >= 11 && numberOfNumeric <= 19) {
      numberType = 5;
    } else {
      numberType = LocalizationService.getNumberTypeOtherFromFive(numberOfNumeric);
    }

    return numberType;
  }

  private getNumeralWithEnding(
    numberOfNumeric: number,
    numericType: NumericType,
    simpleType: boolean = false
  ): string {
    let numberType: NumericNumberType;

    if (numberOfNumeric < 0) {
      numberOfNumeric = Math.abs(numberOfNumeric);
    }

    if (simpleType) {
      numberType = numberOfNumeric === 1 ? 1 : 5;
    } else {
      numberType = LocalizationService.getNumberTypeIfNotSimple(numberOfNumeric);
    }

    return this.getNumericByType(numericType, numberType);
  }
}
