import { Injectable } from '@angular/core';
import { ProfileSelection } from 'src/app/shared/models';

@Injectable()
export class SelectionService {
  private selection: ProfileSelection = {
    type: 'none',
    allProfiles: false,
    range: null,
    docIds: null
  };

  private updateCallback: () => void | null = null;
  private pageSelectionCallback: (setToSelected: boolean) => void | null = null;
  private callbackIsActive = false;

  getSelection(): ProfileSelection {
    return this.selection;
  }

  isProfileSelected(docId: string, profileRangePosition: number): boolean {
    switch (this.selection.type) {
      case 'all':
        return true;
      case 'ids':
        return this.selection.docIds.has(docId);
      case 'range':
        return (
          profileRangePosition >= this.selection.range.from &&
          profileRangePosition <= this.selection.range.to
        );
      default:
        return false;
    }
  }

  reset(triggerUpdate = true): void {
    this.selection = {
      type: 'none',
      allProfiles: false,
      range: null,
      docIds: null
    };

    if (triggerUpdate) {
      this.emitUpdate();
    }
  }

  selectProfile(docId: string, selected: boolean): void {
    if (!this.selection.docIds) {
      this.reset(false);
      this.selection.type = 'ids';
      this.selection.docIds = new Set();
    }

    if (selected) {
      this.selection.docIds.add(docId);
    } else {
      this.selection.docIds.delete(docId);
    }

    this.emitUpdate();
  }

  selectRange(from: number, to: number): void {
    this.reset(false);
    this.selection.type = 'range';
    this.selection.range = { from, to };
    this.emitUpdate();
  }

  selectAll(): void {
    this.reset(false);
    this.selection.type = 'all';
    this.selection.allProfiles = true;
    this.emitUpdate();
  }

  requestThisPageSelection(resetAlreadySelected: boolean, select: boolean): void {
    if (resetAlreadySelected) {
      this.reset(false);
    }

    this.emitPageSelection(select);
    this.emitUpdate();
  }

  setUpdateCallback(callback: () => void): void {
    this.updateCallback = callback;
  }

  setPageSelectionCallback(callback: (setToSelected: boolean) => void): void {
    this.pageSelectionCallback = callback;
  }

  /** SelectionService don't keep track of number for select all so totalProfiles needs to be provided. */
  getSelectedTotal(totalProfiles: number): number {
    switch (this.selection.type) {
      case 'all':
        return totalProfiles;
      case 'range':
        return this.selection.range.to - this.selection.range.from + 1;
      case 'ids':
        return this.selection.docIds.size;
      default:
        return 0;
    }
  }

  private emitUpdate(): void {
    if (this.callbackIsActive) return;

    this.callbackIsActive = true;
    this.updateCallback?.();
    this.callbackIsActive = false;
  }

  private emitPageSelection(setToSelected: boolean): void {
    if (this.callbackIsActive) return;

    this.callbackIsActive = true;
    this.pageSelectionCallback?.(setToSelected);
    this.callbackIsActive = false;
  }
}
