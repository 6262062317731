<div *ngIf="activePage && numberOfPages" class="pagination">
  <div 
    class="pagination__arrow"
    (click)="onPrevPage()"
    [class.disabled]="activePage === 1">
    <svg width="20" height="20" class="icon">
      <use xlink:href="#arrow-left"></use>
    </svg>
  </div>

  <div
    #paginationButton
    class="pagination__selector"
    [class.dropdown-on]="dropdown?.isShown"
    [class.disabled]="numberOfPages < 2"
    (click)="togglePaginationDropdown()">
    <span class="value">{{ activePage }}</span>
    
    <svg width="20" height="20" class="icon">
      <use xlink:href="#arrow-down"></use>
    </svg>

    <app-custom-dropdown 
      #pageSelectionDropdown 
      [reference]="paginationButton" 
      [withBackdrop]="true">
      <app-page-select-dropdown 
        [pages]="numberOfPages"
        [activePage]="activePage"
        [isShown]="dropdown?.isShown"
        [maxPage]="maxPage"
        (pageSelected)="selectPage($event)">
      </app-page-select-dropdown>
    </app-custom-dropdown>
  </div>

  <div 
    class="pagination__arrow" 
    (click)="onNextPage()" 
    [class.disabled]="activePage === numberOfPages || activePage >= maxPage">
    <svg width="20" height="20" class="icon">
      <use xlink:href="#arrow-right"></use>
    </svg>
  </div>
</div>
