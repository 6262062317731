import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Autocomplete, FacetType, SearchHistoryQueries } from '../../../../../shared/models';
import { FiltersData } from './filters.data';
import { SearchCategoryTypeEnum } from '../../../../../shared/enums';
import { FiltersUtilities } from './filters.utilities';
import { SearchFilter } from '../../../../system-shared/classes';
import { ParseFiltersInfo } from '../../../../system-shared/models/search-filter';
import { SearchQueriesInfo } from '../../../../system-shared/services';

@Injectable()
export class FiltersService {
  constructor() {}

  private filters: SearchFilter[];
  private updateFilters$ = new BehaviorSubject<SearchFilter[]>(null);

  // ******************************** Public availability ********************************

  // *************** 1. Filters ***************
  // 1.1 this.filters manipulations

  filters$: Observable<SearchFilter[]> = this.updateFilters$.asObservable();

  private updateFilters(filters: SearchFilter[]): void {
    this.filters = filters;

    this.updateFilters$.next(filters);
  }

  resetFilters(): void {
    this.updateFilters([]);
  }

  setDefaultFilters(): void {
    // For new search
    const filters: SearchFilter[] = FiltersData.getDefaultFilters();

    this.updateFilters(filters);
  }

  setDefaultSearchFilters(defaultLocation: Autocomplete): void {
    // For default search
    const parsedFilters: SearchFilter[] = FiltersUtilities.getDefaultSearchFilters(defaultLocation);

    this.updateFilters(parsedFilters);
  }

  setFiltersByRequestInfo(parseFiltersInfo: ParseFiltersInfo): void {
    // For existed search
    let resultFilters: SearchFilter[];
    const doesFiltersExists: boolean = this.doesFiltersExists();

    if (doesFiltersExists) {
      this.setFacetToExistedFilters(parseFiltersInfo);

      resultFilters = this.filters;
    } else {
      resultFilters = FiltersUtilities.getParsedFilters(parseFiltersInfo);
    }

    this.updateFilters(resultFilters);
  }

  resetOtherQueries(exceptionQueryType: SearchCategoryTypeEnum): void {
    this.filters.forEach((item: SearchFilter) => {
      item.resetOtherQueries(exceptionQueryType);
    });
  }

  // *************** 1.3 Private filter logic *******************

  private doesFiltersExists(): boolean {
    return this.filters?.length > 0;
  }

  private setFacetToExistedFilters(parseFiltersInfo: ParseFiltersInfo): void {
    if (!this.filters?.length) {
      return;
    }

    const { facetResponse } = parseFiltersInfo;

    this.filters.forEach((item: SearchFilter) => {
      item.setFacetByResponse(facetResponse);
    });
  }

  // *************** 2. Data handling ***************:

  composeQueriesInfo(forAPI: boolean = false, filters: SearchFilter[] = null): SearchQueriesInfo {
    let queryList: string[] = [];
    let filterList: string[] = [];
    const rawFilters: boolean = !!filters;

    if (!filters) filters = this.filters;

    filters?.forEach((item: SearchFilter) => {
      const queryInfo: SearchQueriesInfo = item.composeQueryInfo(forAPI, rawFilters);

      if (queryInfo.queryList) {
        queryList = queryList.concat(queryInfo.queryList);
      }

      if (queryInfo.filterList) {
        filterList = filterList.concat(queryInfo.filterList);
      }
    });

    return { queryList, filterList };
  }

  getRevealedFilterFacets(): FacetType[] {
    let facets: FacetType[] = [];

    if (!this.filters?.length) {
      return facets;
    }

    this.filters.forEach((item: SearchFilter) => {
      if (item.data.revealed) {
        const itemFacets: FacetType[] = item.getFacetTypes();

        facets = facets.concat(itemFacets);
      }
    });

    return facets;
  }

  getFiltersForHistoryList(info: ParseFiltersInfo): SearchHistoryQueries[] {
    return FiltersUtilities.getParsedFilters(info).reduce(
      (acc: SearchHistoryQueries[], item: SearchFilter) => {
        item.setLabelMaps(item.data.label);

        const labelMaps: SearchHistoryQueries = item.data.labelMaps;

        if (labelMaps.size) {
          acc.push(labelMaps);
        }

        return acc;
      },
      []
    );
  }

  static areSearchQueryInfosDiffer(
    a: SearchQueriesInfo | null,
    b: SearchQueriesInfo | null
  ): boolean {
    if (a === null || b === null) return true;

    if (
      a.filterList?.length !== b.filterList?.length ||
      a.queryList?.length !== b.queryList?.length
    ) {
      return true;
    }

    const filtersDiffer = a.filterList.some(
      (val: string, ind: number) => val !== b.filterList[ind]
    );
    const queriesDiffer = a.queryList.some((val: string, ind: number) => val !== b.queryList[ind]);

    return filtersDiffer || queriesDiffer;
  }
}
