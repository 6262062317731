import {
  ContactEnum,
  ContactFilterEnum,
  ContactFilterItem,
  ContactFilterType,
  FacetResponseData,
  QueryLabelInfo,
  SpecificFacetType,
  WithContactsFilterType
} from '../../models';
import { Facets } from '../../../modules/search/shared/services';

export class ContactFilter {
  private separator = '_';
  private contactParsingInfo = {
    [ContactEnum.EMAIL]: ContactFilterEnum.WITH_CONTACTS,
    [ContactEnum.PHONE]: ContactFilterEnum.WITH_CONTACTS,
    ['false']: ContactFilterEnum.NO_CONTACTS,
    ['any']: ContactFilterEnum.ALL_CONTACTS
  };

  constructor(public data: ContactsFilterInfo) {}

  doesHaveAnyValue(): boolean {
    return Object.keys(this.data).some((key: string) => {
      if (this.data[key].checked) {
        if (key === ContactFilterEnum.WITH_CONTACTS) {
          const emailChecked: boolean = this.data[key][ContactEnum.EMAIL].checked;
          const phoneChecked: boolean = this.data[key][ContactEnum.PHONE].checked;

          return emailChecked || phoneChecked;
        }

        return true;
      }
    });
  }

  getContentString(): string {
    let filter = '';

    const { withContacts, noContacts, allContacts } = this.data;

    if (
      (withContacts.checked && noContacts.checked) ||
      (!withContacts.checked && !noContacts.checked)
    ) {
      filter = allContacts.valueForParsing;
    } else {
      if (withContacts.checked) {
        const emailChecked: string = this.data.withContacts.email.checked ? ContactEnum.EMAIL : '';
        const phoneChecked: string = this.data.withContacts.phone.checked ? ContactEnum.PHONE : '';

        if (emailChecked || phoneChecked) {
          const pointBetween = emailChecked && phoneChecked ? this.separator : '';

          filter = `${emailChecked}${pointBetween}${phoneChecked}`;
        }
      } else if (noContacts.checked) {
        filter = noContacts.valueForParsing;
      } else if (allContacts.checked) {
        filter = allContacts.valueForParsing;
      }
    }

    return filter;
  }

  setDataFromSearchLine(searchItem: string, facetResponse?: FacetResponseData): void {
    if (searchItem?.length && this.data && this.separator) {
      const itemList: string[] = searchItem?.split(this.separator);

      if (itemList?.length) {
        const valueForParsing: string = itemList[0];
        const contactType: string = this.contactParsingInfo[valueForParsing];

        if (valueForParsing && contactType && this.data[contactType]) {
          this.setFacets(facetResponse);
          this.handleSetDataFromSearchLine(itemList, valueForParsing, contactType);
        }
      }
    }
  }

  setFacets(data: FacetResponseData): void {
    const filterTypes: SpecificFacetType[] = Facets.getFacetFilterTypes(data);

    Object.keys(this.data).forEach((key: string) => {
      const item: ContactFilterItem = this.data[key];

      if (item.specificFacetType && filterTypes.includes(item.specificFacetType)) {
        this.data[key].facet = data[item.specificFacetType];
      }

      if (item.email?.specificFacetType && filterTypes.includes(item.email.specificFacetType)) {
        this.data[key].email.facet = data[item.email.specificFacetType];
      }

      if (item.phone?.specificFacetType && filterTypes.includes(item.phone.specificFacetType)) {
        this.data[key].phone.facet = data[item.phone.specificFacetType];
      }
    });
  }

  onValueChanged(
    type: ContactFilterType,
    withContactFilterType: WithContactsFilterType = null
  ): void {
    const { withContacts, noContacts, allContacts } = this.data;

    if (type === 'withContacts') {
      withContacts.checked = withContactFilterType ? true : !withContacts.checked;
      noContacts.checked = false;
      allContacts.checked = false;
    } else if (type === 'noContacts') {
      noContacts.checked = !noContacts.checked;
      withContacts.checked = false;
      allContacts.checked = false;

      this.onWithContactsFilterChanged();
    } else if (type === 'allContacts') {
      allContacts.checked = !allContacts.checked;
      withContacts.checked = false;
      noContacts.checked = false;

      this.onWithContactsFilterChanged();
    }
  }

  getLabelInfoList(): QueryLabelInfo[] {
    const list: QueryLabelInfo[] = [];
    const { withContacts, noContacts, allContacts } = this.data;
    const { email, phone } = withContacts;
    let value: string;

    if (withContacts.checked && (email.checked || phone.checked)) {
      if (email.checked) list.push({ value: email.labelFull });
      if (phone.checked) list.push({ value: phone.labelFull });
    } else if (noContacts.checked) {
      value = 'FILTER.CONTACTS.NO_CONTACTS';
    } else if (!withContacts.checked && !noContacts.checked && !allContacts.checked) {
      value = 'FILTER.CONTACTS.ANY_CONTACTS';
    }

    if (value) list.push({ value });

    return list;
  }

  private onWithContactsFilterChanged(): void {
    const { withContacts } = this.data;

    if (!withContacts.checked) {
      withContacts.contactsTypeList.forEach((key: string) => {
        if (withContacts[key].checked) {
          withContacts[key].checked = false;
        }
      });
    }
  }

  private handleSetDataFromSearchLine(
    itemList: string[],
    valueForParsing: string,
    contactType: string
  ): void {
    if (valueForParsing === this.data[contactType]?.valueForParsing) {
      this.resetAll();

      this.data[contactType].checked = true;
    } else if (valueForParsing === ContactEnum.EMAIL || valueForParsing === ContactEnum.PHONE) {
      this.resetAll();

      this.data[contactType].checked = true;

      itemList?.forEach((item: string) => {
        if (
          this.data[contactType][item] &&
          (item === ContactEnum.EMAIL || item === ContactEnum.PHONE)
        ) {
          this.data[contactType][item].checked = true;
        }
      });
    }
  }

  private resetAll(): void {
    Object.keys(this.data).forEach((key: string) => {
      this.data[key].checked = false;

      if (this.data[key].contactsTypeList?.length) {
        this.data[key].contactsTypeList.forEach((typeKey: string) => {
          this.data[key][typeKey].checked = false;
        });
      }
    });
  }
}

export class ContactsFilterInfo {
  constructor(
    public withContacts: ContactFilterItem,
    public noContacts: ContactFilterItem,
    public allContacts: ContactFilterItem
  ) {}
}
