import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { PaginationInfo, PageSelectionInfo } from 'src/app/shared/models';
import { SelectionService } from '../../services/selection.service';
import { CustomDropdownComponent } from 'src/app/modules/platform/components';

@Component({
  selector: 'app-profile-mass-selection',
  templateUrl: './profile-mass-selection.component.html',
  styleUrls: ['./profile-mass-selection.component.scss']
})
export class ProfileMassSelectionComponent implements OnChanges {
  @Input() paginationInfo: PaginationInfo;
  @Input() pageSelectionInfo: PageSelectionInfo;
  @Input() loading: boolean = true;
  @Input() maxSelectableProfiles = Infinity;
  @Input() withRange = true;

  @ViewChild('selectionDropdown') public selectionDropdown: CustomDropdownComponent;

  checkboxState: 'empty' | 'check' | 'minus' = 'empty';

  constructor(private selectionService: SelectionService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pageSelectionInfo']) {
      this.setCheckboxState(changes['pageSelectionInfo'].currentValue);
    }

    if (changes['loading'] && changes['loading'].currentValue) {
      this.closeDropdown();
    }
  }

  private setCheckboxState(info?: PageSelectionInfo): void {
    if (!info?.selected) {
      this.checkboxState = 'empty';
      return;
    }

    if (info.selected >= info.total) {
      this.checkboxState = 'check';
    } else if (info.selected > 0) {
      this.checkboxState = 'minus';
    } else {
      this.checkboxState = 'empty';
    }
  }

  onCheckboxClick(): void {
    if (this.loading) {
      return;
    }

    const select = this.checkboxState === 'empty';
    this.selectionService.requestThisPageSelection(false, select);
  }

  toggleDropdown(): void {
    if (this.loading || !this.pageSelectionInfo?.total) {
      return;
    }

    this.selectionDropdown.toggle();
  }

  closeDropdown(): void {
    this.selectionDropdown?.hide();
  }
}
