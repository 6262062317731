import { Injectable } from '@angular/core';
import { Router, UrlSerializer, UrlTree } from '@angular/router';

import {
  AccountPrivilegeType,
  AdminLogDto,
  Autocomplete,
  ConditionInfo,
  FacetResponseData,
  FacetType,
  HandledAutocompleteResponse,
  PaginationInfo,
  SearchHistoryInfo,
  SearchHistoryQueries,
  SearchQueryFromHistory,
  SearchQueryInfo,
  SearchQueryRequestInfo,
  SearchRequestInfo,
  SearchResponse,
  SearchState
} from '../../../../shared/models';
import { SearchData, SearchQueriesInfo, SearchUtilities } from './search.utilities';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Facets, FiltersService, FiltersUtilities } from '../../../search/shared/services';
import { DefaultSearch } from '../../../../shared/enums';
import {
  AutocompleteService,
  LocationService,
  UtilitiesService
} from '../../../../shared/services';
import { ParseFiltersInfo } from '../../models/search-filter';
import { SystemPages } from '../../../../shared/enums/routerPaths.enum';
import { SearchFilter } from '../../classes';

@Injectable()
export class SearchService {
  constructor(
    private router: Router,
    private serializer: UrlSerializer,
    private filtersService: FiltersService,
    private utilities: UtilitiesService,
    private autocompleteService: AutocompleteService,
    private locationService: LocationService
  ) {}

  private state: SearchState = SearchState.SENT;
  private requestInfo: SearchRequestInfo;
  private autocompleteResponse?: HandledAutocompleteResponse;
  private facetResponse?: FacetResponseData;

  private updateState$ = new BehaviorSubject<SearchState>(this.state);
  private updateSearchQuery$ = new Subject<string>();
  private updateLoading$ = new BehaviorSubject<ConditionInfo>({
    condition: false
  });
  private newSearchSubject = new Subject<null>();

  state$: Observable<SearchState> = this.updateState$.asObservable();
  searchQuery$: Observable<string> = this.updateSearchQuery$.asObservable();
  loading$: Observable<ConditionInfo> = this.updateLoading$.asObservable();
  newSearch$: Observable<null> = this.newSearchSubject.asObservable();

  // ******************** 1. Loading *********************

  startLoading(): void {
    this.updateLoading(true);
  }

  finishLoading(): void {
    this.updateLoading(false);
  }

  private updateLoading(condition: boolean): void {
    this.updateLoading$.next({ condition });
  }

  // ******************** 1. State *********************

  updateFiltersValidity(filtersValid: boolean): void {
    filtersValid ? this.updateState(SearchState.NEW) : this.updateState(SearchState.NEW_DISABLED);
  }

  // ******************** 3. Search ********************

  // 3. Launch search

  search(): void {
    // Launch search (on click on Search btn or automatically for launch default search)
    const info: SearchQueriesInfo = this.filtersService.composeQueriesInfo();

    this.updateRequestInfo(info, true);
    this.updateState(SearchState.SENT);

    this.newSearchSubject.next(null);
    this.router.navigate([SystemPages.SEARCH], { queryParams: this.requestInfo }).then(() => {});
  }

  searchByQuery(query: string, newTab: boolean = false): void {
    // launch search by query (from Saved Searches, Search History, Admin Panel users logs)
    const searchRequestInfo: SearchRequestInfo = this.getSearchRequestInfoBySearchItem(query);

    if (newTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([SystemPages.SEARCH], { queryParams: searchRequestInfo })
      );

      window.open(url, '_blank');
    } else {
      this.filtersService.resetFilters();

      this.newSearchSubject.next(null);
      this.router
        .navigate(['/'])
        .then(() => {
          return this.router.navigate([SystemPages.SEARCH], { queryParams: searchRequestInfo });
        })
        .then(() => {});
    }
  }

  updateSearch(data: SearchData): void {
    // Launch existed search with updated data (sorting, order or page number)
    this.startLoading();

    if (data) {
      this.updateRequestInfo(data, false);
    }

    this.router.navigate([SystemPages.SEARCH], { queryParams: this.requestInfo }).then(() => {});
  }

  updateSearchContent(data: SearchData): void {
    this.startLoading();
    this.updateRequestInfo(data, false);
    this.filtersService.resetFilters();
    this.filtersService.setFiltersByRequestInfo({
      requestInfo: this.requestInfo,
      autocompleteResponse: this.autocompleteResponse,
      facetResponse: this.facetResponse
    });

    const requestInfoForApi = this.getRequestInfoForApi(this.requestInfo);
    const searchQueryForApi: string = this.getSearchQuery(requestInfoForApi);
    this.updateSearchQuery(searchQueryForApi);
    this.updateState(SearchState.SENT);

    // Update history and browser navigation bar url
    const searchQuery = this.getSearchQuery(this.requestInfo);
    this.locationService.replaceQuery(searchQuery);
  }

  navigateToEmptySearch(): void {
    // Navigate to new (empty) search (this method resets previous search if such exists)
    this.router.navigate(['/']).then(() => {});
  }

  replaceQueryByPage(pageNumber: number): void {
    if (this.requestInfo && pageNumber) {
      this.requestInfo = { ...this.requestInfo, pageNumber };
      const searchQuery: string = this.getSearchQuery(this.requestInfo);

      this.locationService.replaceQuery(searchQuery);
    }
  }

  // 3.2 Display search

  // Clear from here
  showNewSearch(): void {
    // Display new (empty) search (instruction gets from url empty params)
    this.resetSearchInfo();
    this.updateState(SearchState.EMPTY);
    this.filtersService.setDefaultFilters();

    const searchQuery: string = '';
    this.locationService.replaceQuery('');

    this.newSearchSubject.next(null);
    this.updateSearchQuery(searchQuery);
  }

  showDefaultSearch(defaultLocation: Autocomplete): void {
    // Display default search (instruction gets from url query param)
    this.filtersService.setDefaultSearchFilters(defaultLocation);
    this.search();
  }

  showSentSearch(parseFiltersInfo: ParseFiltersInfo): void {
    // Display search from url
    // (instruction gets from url by existed query params, converted to SearchRequestInfo)
    this.startLoading();

    const { requestInfo } = parseFiltersInfo;

    this.setSearchInfo(parseFiltersInfo);
    this.updateState(SearchState.SENT);
    this.filtersService.setFiltersByRequestInfo(parseFiltersInfo);

    const queriesInfo: SearchQueriesInfo = this.filtersService.composeQueriesInfo(true);
    const requestInfoForApi: SearchRequestInfo = SearchUtilities.requestInfoForApi(
      requestInfo,
      queriesInfo
    );
    const searchQuery: string = this.getSearchQuery(requestInfoForApi);

    this.updateSearchQuery(searchQuery);
  }

  // 3.3 Search getters:

  getPageNumberFromUrl(): number | null {
    return this.requestInfo.pageNumber ? +this.requestInfo.pageNumber : null;
  }

  getPaginationInfoByResponse(searchResponse: SearchResponse): PaginationInfo {
    if (searchResponse) {
      return {
        numFound: searchResponse?.numFound,
        page: searchResponse?.page,
        pageSize: searchResponse?.pageSize
      };
    }

    return null;
  }

  getSearchRequestInfoBySearchItem(searchQuery: string): SearchRequestInfo {
    if (searchQuery) {
      const queryQuestionMarkMatch: boolean = !!searchQuery?.match(/\?/);
      let query: string = searchQuery;

      if (!queryQuestionMarkMatch) {
        query = `?${query}`;
      }

      const urlTree: UrlTree = this.serializer.parse(query);

      return urlTree?.queryParams as SearchRequestInfo;
    }

    return null;
  }

  getSearchQueryForSavingSearch(searchName: string, searchRequestInfo): SearchQueryRequestInfo {
    if (searchName && searchRequestInfo) {
      const searchRequestInfoWithoutPageNumber: SearchRequestInfo = {
        ...searchRequestInfo
      };

      delete searchRequestInfoWithoutPageNumber.pageNumber;

      let queryString: string = this.getSearchQuery(searchRequestInfoWithoutPageNumber);
      queryString = queryString?.replace('?', '');

      const searchNameNormalized: string = searchName?.trim();

      return {
        name: searchNameNormalized,
        query: queryString
      };
    }

    return null;
  }

  // ****************** 4. Set data inner methods ******************

  private resetSearchInfo(): void {
    this.requestInfo = {};
    this.autocompleteResponse = null;
    this.facetResponse = null;
  }

  private setSearchInfo(info: ParseFiltersInfo): void {
    this.requestInfo = { ...info.requestInfo };
    this.autocompleteResponse = info.autocompleteResponse;
    this.facetResponse = info.facetResponse;
  }

  private updateState(state: SearchState): void {
    this.state = state;

    this.updateState$.next(state);
  }

  private updateSearchQuery(searchQuery: string): void {
    this.updateSearchQuery$.next(searchQuery);
  }

  private updateRequestInfo(data: SearchData, withQueries: boolean = false): void {
    if (this.utilities.doesObjectEmpty(this.requestInfo)) {
      this.requestInfo = SearchUtilities.getSearchRequestInfo(data);
    } else {
      this.updateExistedRequestInfo(data, withQueries);
    }
  }

  private updateExistedRequestInfo(data: SearchData, withQueries: boolean): void {
    const { pageNumber, sort, order, queryList, filterList } = data;

    if (withQueries) {
      this.requestInfo.pageNumber = DefaultSearch.PAGE_NUMBER;
    } else if (pageNumber && pageNumber !== this.requestInfo.pageNumber) {
      this.requestInfo.pageNumber = pageNumber;
    }

    if (sort && sort !== this.requestInfo.sort) {
      this.requestInfo.sort = sort;
      this.requestInfo.pageNumber = DefaultSearch.PAGE_NUMBER;
    }

    if (order && order !== this.requestInfo.order) {
      this.requestInfo.order = order;
      this.requestInfo.pageNumber = DefaultSearch.PAGE_NUMBER;
    }

    if (withQueries) {
      this.requestInfo.q = queryList?.length ? queryList : [];
      this.requestInfo.f = filterList?.length ? filterList : [];
    }
  }

  // ****************** 5. Get search query methods *******************

  // 5.1 Public search query methods

  getSearchQuery(requestInfo: SearchRequestInfo = null): string {
    if (!requestInfo) {
      requestInfo = SearchUtilities.getSearchRequestInfo();
    }

    return this.getSearchQueryByInfo(requestInfo);
  }

  getSearchQueryForApi(): string {
    if (!this.requestInfo) {
      return '';
    }

    const infoForApi = this.getRequestInfoForApi(this.requestInfo);
    infoForApi.pageNumber = undefined;
    return this.getSearchQueryByInfo(infoForApi);
  }

  getQueryForSearchObjects(requestInfo: SearchRequestInfo): string {
    return this.getSearchQuery(requestInfo);
  }

  getCurrentSearchQuery(): string {
    return this.requestInfo ? this.getSearchQueryByInfo(this.requestInfo) : '';
  }

  private getRequestInfoForApi(requestInfo: SearchRequestInfo): SearchRequestInfo {
    const filtersForAPI: SearchFilter[] = FiltersUtilities.getParsedFilters({
      requestInfo
    });
    const queriesInfo: SearchQueriesInfo = this.filtersService.composeQueriesInfo(
      true,
      filtersForAPI
    );
    return SearchUtilities.requestInfoForApi(requestInfo, queriesInfo);
  }

  getSearchQueryInfo(requestInfo: SearchRequestInfo): SearchQueryInfo {
    const requestInfoForApi = this.getRequestInfoForApi(requestInfo);
    const queryForSearchObjects: string = this.getQueryForSearchObjects(requestInfoForApi);
    const queryWithFacets: string = this.getSearchQueryWithFacets(requestInfoForApi);

    return {
      queryForSearchObjects,
      queryWithFacets
    };
  }

  getCurrentSearchQueryWithFacets(facetTypes: FacetType[]): string {
    if (!facetTypes?.length) {
      return '';
    }

    const requestInfoForApi = this.getRequestInfoForApi(this.requestInfo);

    return this.getSearchQueryWithFacets(requestInfoForApi, facetTypes);
  }

  getRequestInfoFromQuery(query: string): SearchRequestInfo {
    const tree: UrlTree = this.serializer.parse(`?${query}`);
    const queries: string[] = tree.queryParams?.q;
    const filters: string[] = tree.queryParams?.f;

    return {
      q: queries,
      f: filters
    };
  }

  getSearchHistoryMaps(queriesInfo: SearchQueryFromHistory[]): SearchHistoryInfo[] {
    return queriesInfo.map((item: SearchQueryFromHistory) => this.getSearchHistoryMap(item));
  }

  getSearchHistoryMapByLog(log: AdminLogDto): SearchHistoryInfo {
    const queryInfo: SearchQueryFromHistory = this.getSearchQueryFromHistoryByLog(log);

    return this.getSearchHistoryMap(queryInfo);
  }

  // 5.2 Private search query methods

  private getSearchQueryWithFacets(
    requestInfo: SearchRequestInfo,
    facets: FacetType[] = null
  ): string {
    if (!facets?.length) {
      facets = this.getFacetsFromRequestInfo(requestInfo);
    }

    if (facets.length) {
      const resultRequestInfo: SearchRequestInfo = {
        ...requestInfo,
        facet: facets
      };

      return this.getSearchQuery(resultRequestInfo);
    }

    return null;
  }

  private getFacetsFromRequestInfo(requestInfo: SearchRequestInfo): FacetType[] {
    const reveledFiltersFacets: FacetType[] = this.filtersService.getRevealedFilterFacets();
    const facetsByRequestInfo: FacetType[] = Facets.getFacetsFromRequestInfo(requestInfo);
    const combinedFacets: FacetType[] = reveledFiltersFacets.concat(facetsByRequestInfo);

    return [...new Set(combinedFacets)];
  }

  private getSearchQueryByInfo(searchRequestInfo: SearchRequestInfo): string {
    const tree: UrlTree = this.router.createUrlTree([], {
      queryParams: searchRequestInfo
    });
    const url: string = this.serializer.serialize(tree);
    const fullyEncodedUrl: string = SearchService.getFullyEncodedUrl(url);

    return SearchService.getQueryStringFromUrl(fullyEncodedUrl);
  }

  private getSearchHistoryMap(queryInfo: SearchQueryFromHistory): SearchHistoryInfo {
    const { query, dm } = queryInfo;
    const info: ParseFiltersInfo = this.getParseFiltersInfo(queryInfo);
    const queries: SearchHistoryQueries[] = this.filtersService.getFiltersForHistoryList(info);

    return { query, dm, queries };
  }

  private getSearchQueryFromHistoryByLog(log: AdminLogDto): SearchQueryFromHistory {
    const { query, autocompleteDto, dc } = log;

    return { query, autocompleteDto, dm: dc };
  }

  private getParseFiltersInfo(info: SearchQueryFromHistory): ParseFiltersInfo {
    const { query, autocompleteDto } = info;
    const requestInfo: SearchRequestInfo = this.getRequestInfoFromQuery(query);
    const handledAutocomplete: HandledAutocompleteResponse =
      this.autocompleteService.getHandledAutocompleteResponse(autocompleteDto);

    return {
      requestInfo,
      autocompleteResponse: handledAutocomplete
    };
  }

  private static getQueryStringFromUrl(url: string): string {
    const queryStringFullList: string[] = url?.split('?');

    return queryStringFullList?.length > 1 ? `?${queryStringFullList[1]}` : '';
  }

  private static getFullyEncodedUrl(url: string): string {
    const encodedComma: string = encodeURIComponent(',');

    return url.replace(/,/g, encodedComma);
  }

  static getRequiredPriviligeForSearchRequest(
    parsedFilters: SearchFilter[]
  ): AccountPrivilegeType | null {
    let requiredPriviligeType: AccountPrivilegeType | null = null;

    const nonEmptyFilters = parsedFilters.filter((f) => f.doesHaveAnyQuery());
    if (nonEmptyFilters?.length > 0) {
      if (nonEmptyFilters.some((f) => f.isForProfessionalPlanOnly())) {
        requiredPriviligeType = 'paid';
      }

      if (nonEmptyFilters.some((f) => f.isForSuperAdminOnly())) {
        requiredPriviligeType = 'superAdmin';
      }
    }

    return requiredPriviligeType;
  }
}
