import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ScrollbarComponent } from 'src/app/scrollbar/scrollbar.component';

@Component({
  selector: 'app-page-select-dropdown',
  templateUrl: './page-select-dropdown.component.html',
  styleUrls: ['./page-select-dropdown.component.scss']
})
export class PageSelectDropdownComponent implements OnInit {
  readonly entryHeight = 45;

  pages: number[] = [];
  totalPages: number = 1;

  @Input() maxPage = Infinity;

  @Input() activePage = 1;
  @Input('pages') set setPages(pages: number) {
    this.totalPages = pages;
    this.updatePaginator();
  }
  @Input() set isShown(isShown: boolean) {
    if (isShown) {
      this.scrollToActivePage();
    }
  }

  @Output() pageSelected = new EventEmitter<number>();

  @ViewChild('scrollbar') scrollElementRef: ScrollbarComponent;

  ngOnInit(): void {
    this.updatePaginator();
  }

  private updatePaginator(): void {
    const showPages = Math.min(this.totalPages, this.maxPage);
    this.pages = Array.from(Array(showPages)).map((val, index) => index + 1);
  }

  selectPage(pageNumber: number): void {
    if (pageNumber !== this.activePage) {
      this.pageSelected.emit(pageNumber);
    }
  }

  scrollToActivePage(): void {
    let top = (this.activePage - 2) * this.entryHeight;
    top = Math.max(0, top);

    this.scrollElementRef.onVertical(top);
  }
}
