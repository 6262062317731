<div class="dropdown">
  <div class="container">
    <app-scrollbar
    #scrollbar
    class="scrollbar"
    [vertical]="true"
    [size]="'standard'"
    [theme]="'light'"
    [visibility]="'native'">
    <ng-container *ngFor="let page of pages">
      <div class="page" [class.current]="page === activePage" (click)="selectPage(page)">
        {{ page }}
      </div>
    </ng-container>
    </app-scrollbar>
  </div>
</div>