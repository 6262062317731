<div
  *ngIf="downloadInfo$ | async as downloadInfo"
  class="download-cv">
  <button
    #exportBtn
    type="button"
    class="btn btn--icon download-cv__btn"
    [ngClass]="{'in-progress': downloadInfo.isInProgress}"
    [disabled]="limitAccess || downloadInfo.isInProgress || cleverStaffModalIsPending"
    (click)="startExport()">
    <ng-container *ngIf="!downloadInfo.isInProgress; else downloadInProgress">
      <svg
        class="icon"
        width="16"
        height="16">
        <use xlink:href="#download2">
        </use>
      </svg>
    </ng-container>

    <ng-template #downloadInProgress>
      <app-loading-animation></app-loading-animation>
    </ng-template>
  </button>

  <app-custom-dropdown 
  #exportMenuDropdown 
  [reference]="exportBtn"
  [withBackdrop]="true">
  <app-export-selector-menu
    class="profile-page-export"
    [forProfileCard]="true"
    (cvExport)="download()"
    (cleverStaffExport)="openCleverStaffExportModal()">
  </app-export-selector-menu>
</app-custom-dropdown>
</div>
