<div
  *ngIf="status"
  appFolderStatus
  class="folder-status {{ type }} {{ status.color }}"
  [type]="type"
  [active]="isActive"
  [noMove]="noMove"
  [status]="status"
  (click)="onClickOnStatus()">
  <app-folder-status-circle
    class="folder-status__circle"
    [type]="type"
    [color]="status.color"
    [active]="isActive"
    [clickable]="type === 'full-actions'"
    (editStatusColor)="clickEditStatusColor()">
  </app-folder-status-circle>

  <div class="folder-status__name" [ngClass]="{'no-move': status.lastDefault}">
    <h4 class="folder-status__text">
      {{ status.nameForTranslate | translate }}
    </h4>
  </div>

  <div *ngIf="type === 'filter' && status.candidateNumber >= 0" class="folder-status__number">
    <h5 class="folder-status__text">
      {{ status.candidateNumber | numberWithSpaces }}
    </h5>
  </div>

  <div
    *ngIf="type === 'full-actions' && !status.systemStatus"
    class="folder-status__actions">
    <svg
      width="12"
      height="12"
      class="edit-folder-status-btn icon"
      (click)="clickEditStatusName()">
      <use xlink:href="#edit-pencil"></use>
    </svg>

    <svg
      *ngIf="profileUserRoleLabel === 'admin' || (status.userId && status.userId === currentUserId)"
      width="10"
      height="10"
      class="remove-folder-status-btn icon"
      (click)="clickRemoveStatus()">
      <use xlink:href="#large-cross"></use>
    </svg>
  </div>
</div>
