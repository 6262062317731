import { Candidate } from '../candidate';
import {
  CandidateFromFolderMoveType,
  CandidateToFolderActionType,
  FilterFolderAccess,
  FilterFolderState,
  FolderAccessType,
  FolderActionType,
  FolderStatusActionType,
  FolderStatusColorType,
  FolderStatusesInfo,
  ItemEditActionType,
  SelectedCandidatesActionType,
  SystemColors
} from '../../types';
import { NotificationTypeEnum, RolesTypesEnum } from '../../enums';

export interface Folder extends FolderParams {
  archived?: boolean;
  dc?: string;
  description?: string;
  name?: string;

  orgId?: string;
  privateAccess?: boolean;
  profiles?: Candidate[];
  statuses?: FolderStatusesInfo;

  userId?: string;
  candidatesCount?: number;
  candidatesCountByStatuses?: number;
  totalProfilesByStatuses?: TotalProfilesByStatuses;

  tempCandidatesCount?: number;
  haveProfile?: boolean;
  candidateAdded?: boolean;
  active?: boolean;
}

export interface FolderParams extends FolderQueryParams {
  id?: string;
}

export interface FolderQueryParams {
  page?: number;
  count?: number;
  statusIds?: string[];
  returnProfilesOnAllStatuses?: boolean;
}

export interface CandidateFolder {
  date?: string;
  docId: string;
  folderId: string;
  folderName?: string;
  statusId: string;
  statusName?: string;
  archived?: boolean;
}

export interface FolderNavigateState {
  candidates: Candidate[];
  actionType: SelectedCandidatesActionType;
}

export interface TotalProfilesByStatuses {
  [statusId: string]: number;
}

export interface CandidateFoldersInfo {
  [docId: string]: CandidateFolder[];
}

export interface FolderFormValue {
  name: string;
  description: string;
  accessType: FolderAccessType;
  statuses: FolderStatus[];
  selectedStatuses?: FolderStatus[];
}

export interface FolderStatus {
  archived?: boolean;
  color?: FolderStatusColorType;
  defaultStatus?: boolean;
  id?: string;
  name: string;
  orgId?: string;
  systemStatus?: boolean;
  userId?: string;
  position?: number;

  candidateNumber?: number;
  nameForTranslate?: string; // custom, for system statuses translates
  active?: boolean; // custom, for filter
  lastDefault?: boolean; // custom
}

export interface AddProfileInFoldersRequestData {
  profilesToAdd: CandidateFolder[];
}

export interface AddProfileListInFoldersRequestData {
  folderId: string;
  statusId: string;
  allProfiles?: boolean;
  fromRange?: number;
  toRange?: number;
  ids?: string[];
}

export interface FoldersStatusListInfo {
  filteredStatuses: FolderStatus[];
  restStatuses: FolderStatus[];
}

export interface FolderStatusActionInfo {
  statuses?: FolderStatus[];
  type?: FolderStatusActionType;
  name?: string;
}

export interface FolderActionInfo {
  folder: Folder;
  folders?: Folder[];
  type: FolderActionType;
}

export interface CandidatesOnFolderInfo {
  candidates: Candidate[];
  statuses?: FolderStatus[];
}

export type StatusActionTypeNotificationInfo = {
  [action in FolderStatusActionType]: ActionTypeNotification;
};

export type FolderActionTypeNotificationInfo = {
  [action in FolderActionType]: ActionTypeNotification;
};

export type CandidateToFolderNotificationInfo = {
  [action in CandidateToFolderActionType]: ActionTypeNotification;
};

export type SelectedCandidatesNotificationInfo = {
  [action in SelectedCandidatesActionType]: ActionTypeNotification;
};

export interface ActionTypeNotification {
  type: NotificationTypeEnum;
  message: string;
  hasInterpolateParams?: boolean;
  hasMultiple?: boolean;
}

export interface FoldersCountInfo {
  folders?: number;
  statuses?: number;
}

export interface FolderFilter {
  state: FilterFolderState;
  access: FilterFolderAccess;
}

export interface FilterFoldersFlags {
  info: FolderFilter;
  folders?: Folder[];
  filter?: boolean;
  getAllFolders?: boolean;
}

export interface FolderItemStatusesInfo {
  folderId: string;
  statuses: FolderStatus[];
}

export interface CandidateToFolderHandlingData {
  docId: string;
  foldersToAdd: Folder[];
  foldersToRemove?: Folder[];
  candidateFolders?: CandidateFolder[];
  isFolderCreated?: boolean;
}

export interface CandidateToFolderActionInfo {
  docId: string;
  actionType: CandidateToFolderActionType;
  folderName?: string;
  folders?: CandidateFolder[];
}

export interface GetAllFoldersParams {
  withCandidates: boolean;
  docIds?: string;
}

export interface FolderStatusesStatisticResponse {
  [folderId: string]: FolderStatusesStatisticTotal;
  byStatusesTotal: FolderStatusesStatisticTotal;
}

export interface FolderStatusesStatisticTotal {
  [statusId: string]: number;
}

export interface AddCandidateToFolderModalData {
  folders?: Folder[];
  docId: string;
  defaultColor?: SystemColors;
  candidate?: Candidate;
}

export interface AddCandidateToFolderModalResult {
  foldersToAdd: Folder[];
  foldersToRemove: Folder[];
}

export interface FolderNotificationInterpolateParams {
  name?: string;
  multipleInfo?: string;
}

export interface ChangeCandidateFolderStatusModalData {
  statuses: FolderStatus[];
  selectedStatusId?: string;
  candidateLength?: number;
  candidateName?: string;
}

export interface MoveCandidatesToFolderModalData {
  type: CandidateFromFolderMoveType;
  folders: Folder[];
  candidateLength: number;
  candidateName: string;
}

export interface FolderMassActionDto {
  folderId?: string;
  newFolderId?: string;
  newStatusId?: string;
  docIds?: string[];
  statusIds?: string[];
}

export interface CandidatesMoveToFolderActionData {
  type?: CandidateFromFolderMoveType;
  candidates: Candidate[];
  currentFolderId?: string;
  folderToActionId?: string;
  newStatusId?: string;
}

export interface RemoveCandidatesFromFolderData {
  folder: Folder | CandidateFolder;
  candidates: Candidate[];
  isFoldersPage: boolean;
}

export interface EditStatusesInfo {
  statuses: FolderStatus[];
}

export interface EditFolderModalData {
  type: ItemEditActionType;
  folder?: Folder;
  statuses: FolderStatus[];
}

export interface FolderStatusesListFromInfo {
  info: FolderStatusesInfo;
  statusIds?: string[];
  filteredStatusIds?: string[];
}

export interface FolderCurrentUserInfo {
  userId: string;
  roleLabel: RolesTypesEnum;
}

export interface FolderStatusColorInfo {
  type: FolderStatusColorType;
  active?: boolean;
}

export interface HandleAfterEditInfo {
  type?: FolderActionType;
  activeFolder?: Folder;
  activeFolderId?: string;
}

export interface OpenFolderInfo {
  params: FolderParams;
  state?: FolderNavigateState;
  resetSelection?: boolean;
}

export interface FilterFoldersData {
  folder?: Folder;
  folders?: Folder[];
  filter?: boolean;
  isArchiveType?: boolean;
}

export enum FoldersPageState {
  FOLDER = 'folderContent',
  FOLDERS = 'previewFolders'
}

export interface FolderStatusUpdateInfo {
  type: ItemEditActionType;
  status?: FolderStatus;
  statuses?: FolderStatus[];
  prevStatusPosition?: number;
}
