import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Params, Router, RouterEvent } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { LangId, LayoutContentInfo, Locale, SearchRouterNavigateData } from '../models';
import { LocalizationService } from './localization';
import { RouterStateType } from '../types';
import {
  candidatePageRegExp,
  companyPageRegExp,
  cutRedirectLinkRegExp,
  folderPageRegExp,
  invitePageRegExp
} from '../utilities';
import { LangIdsInfo } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class RoutesHandlerService {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private localization: LocalizationService
  ) {}

  private readonly authPages: string[] = ['login', 'registration'];

  private readonly userPages: string[] = [
    'user/profile',
    'user/billing',
    'user/list',
    'user/tags',
    'user/saved-searches',
    'user/folder-statuses',
    'user/settings'
  ];

  private readonly searchPages: string[] = ['search'];

  private layoutContentInfo: LayoutContentInfo = {
    displayContentOnly: false,
    headerExtraClass: '',
    footerExtraClass: '',
    footerTopContent: ''
  };
  layoutContentInfo$ = new BehaviorSubject<LayoutContentInfo>(this.layoutContentInfo);

  getStateByEvent(event: RouterEvent = null, withParams: boolean = false): string {
    const currentUrl: string = event ? event?.url : this.router.url;
    const stateUrl: string = withParams ? currentUrl : currentUrl?.split('?')[0];

    return stateUrl?.slice(1) || '';
  }

  getIsAuthPage(state: string = null, event: RouterEvent = null): boolean {
    state = this.getHandledState(state, event);

    return (
      this.authPages.some((stateItem: string) => state === stateItem) ||
      !!state.match(invitePageRegExp)
    );
  }

  getIsLanding(state: string = null, event: RouterEvent = null): boolean {
    state = this.getHandledState(state, event);

    const mainLanding: boolean = this.getIsMainLanding(state);
    const landingInfoPage: boolean = this.getIsLandingPage(state);

    return mainLanding || landingInfoPage;
  }

  getIsMainLanding(state: string = null, event: RouterEvent = null): boolean {
    state = this.getHandledState(state, event);

    return !state;
  }

  getIsLandingPage(state: string = null, event: RouterEvent = null): boolean {
    state = this.getHandledState(state, event);

    return state === 'info/terms-of-service' || state === 'info/privacy-policy';
  }

  getIsSystemState(state: string = null, event: RouterEvent = null): boolean {
    state = this.getHandledState(state, event);

    const isSearchPage: boolean = this.getIsSearchState(state);
    const isUserProfile: boolean = this.getIsUserState(state);
    const isCompanyPage: boolean = this.isCompanyPage(state);
    const isFolderPage: boolean = this.getIsFolderState(state);

    return isSearchPage || isUserProfile || isCompanyPage || isFolderPage;
  }

  getIsCandidateState(state: string = null, event: RouterEvent = null): boolean {
    state = this.getHandledState(state, event);

    return !!state?.match(candidatePageRegExp);
  }

  getIsFolderState(state: string = null, event: RouterEvent = null): boolean {
    state = this.getHandledState(state, event);

    return state === 'folders' || !!state?.match(folderPageRegExp);
  }

  getIsSearchState(state: string = null, event: RouterEvent = null): boolean {
    state = this.getHandledState(state, event);

    return this.searchPages.some((url: string) => state === url);
  }

  getIsAdminPanelState(state: string = null, event: RouterEvent = null): boolean {
    state = this.getHandledState(state, event);

    return state === 'admin-panel';
  }

  getIsRedirectLinkState(state: string = null, event: RouterEvent = null): boolean {
    state = this.getHandledState(state, event);

    return !!state.match(cutRedirectLinkRegExp);
  }

  getIsUserState(state: string = null, event: RouterEvent = null): boolean {
    state = this.getHandledState(state, event);

    return this.userPages.some((item: string) => {
      const itemStateWithoutQueries: string = RoutesHandlerService.getStateWithoutQueryParams(item);

      return itemStateWithoutQueries === state;
    });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    const routeFirstChild: ActivatedRoute = activatedRoute?.firstChild;

    if (routeFirstChild) {
      return this.getChild(routeFirstChild);
    } else {
      return activatedRoute;
    }
  }

  getPagesTitles(): Observable<string> {
    return this.getChild(this.activatedRoute).data.pipe(
      mergeMap((data: Data) => {
        return this.localization.getTranslate(data.title);
      })
    );
  }

  getRouterEventsAtTheEnd(): Observable<RouterEvent> {
    return this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      tap(() => this.setLayoutContentInfo())
    );
  }

  private setLayoutContentInfo(): void {
    const routeData = this.getRouteData(this.router.routerState.root);
    const {
      displayContentOnly = false,
      headerExtraClass = '',
      footerExtraClass = '',
      footerTopContent = ''
    } = routeData;
    const layoutContentInfo: LayoutContentInfo = {
      displayContentOnly,
      headerExtraClass,
      footerExtraClass,
      footerTopContent
    };

    if (JSON.stringify(layoutContentInfo) !== JSON.stringify(this.layoutContentInfo)) {
      this.layoutContentInfo = {
        ...this.layoutContentInfo,
        ...layoutContentInfo
      };

      this.layoutContentInfo$.next(this.layoutContentInfo);
    }
  }

  private getRouteData(route: ActivatedRoute): any {
    let data = route.snapshot.data;

    if (route.firstChild) {
      data = { ...data, ...this.getRouteData(route.firstChild) };
    }

    return data;
  }

  getRouterQueryParams(): Observable<Params> {
    return this.route.queryParams;
  }

  getRouterState(): RouterStateType {
    const url: string = this.router.url?.slice(1);

    return this.getRouterStateFromUrl(url);
  }

  getRouterStateFromUrl(url: string): RouterStateType {
    const urlWithoutQueryParams: string = url?.split('?')[0];
    const state: string = urlWithoutQueryParams?.replace('user/', '')?.replace('user', '');
    const handledState: string = this.getHandledState(state);

    return handledState === 'profile' || handledState === 'settings' ? handledState : 'other';
  }

  getRouterStateFromEvent(event: RouterEvent): RouterStateType {
    const currentUrl: string = event?.url;
    const stateUrl: string = currentUrl?.split('?')[0];
    const url: string = stateUrl?.slice(1);

    return this.getRouterStateFromUrl(url);
  }

  getIsSystemOnRoutesChangeByEvent(event: RouterEvent): boolean {
    const state: string = this.getHandledState(null, event);

    return (
      event?.id <= 2 ||
      state === 'search' ||
      state === 'user/billing' ||
      state?.includes('user/profile')
    );
  }

  getUserChangeOnRoutesChangeByEvent(event: RouterEvent): boolean {
    const state: string = this.getHandledState(null, event);

    return event?.id !== 1 && (state === 'user/list' || state?.includes('user/profile'));
  }

  routerNavigateByUrlWithExtras({
    url,
    extras,
    id = null
  }: SearchRouterNavigateData): Promise<boolean> {
    const locale: Locale = this.localization.getSystemLocale();
    const langId: LangId = LangIdsInfo[locale];
    const langIdUrlPart: string = `/${langId}`;

    const resultUrl: string = `${langIdUrlPart}${url}`;
    const commands: string[] = id ? [resultUrl, id] : [resultUrl];

    return this.router.navigate(commands, extras);
  }

  setTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  navigateByUrlWithLang(locale: Locale, state: string = null, event: RouterEvent = null): void {
    if (locale) {
      if (!state && !event) {
        state = this.getStateByPure();
      }

      const urlWithLang: string = this.getUrlWithLang(locale, state, event);

      this.router.navigateByUrl(urlWithLang).then(() => {});
    }
  }

  private getUrlWithLang(locale: Locale, state: string = null, event: RouterEvent = null): string {
    state = this.getHandledState(state, event, true);
    const localePath: string = locale && this.isStateHasNotLangId(state) ? `/${locale}` : '';
    const statePath: string = RoutesHandlerService.getStatePathWithLocale(locale, state);

    return `${localePath}${statePath}`;
  }

  private isStateHasNotLangId(state: string = null): boolean {
    return (
      !state || !Object.keys(LangIdsInfo).some((locale: Locale) => state.slice(0, 2) === locale)
    );
  }

  private static getStatePathWithLocale(locale: Locale, state: string = null): string {
    if (state) {
      return state[0] === '?' ? state : `/${state}`;
    }

    return locale ? '' : '/';
  }

  private getHandledState(
    state: string = null,
    event: RouterEvent = null,
    withParams: boolean = false
  ): string {
    if (!state) {
      state = this.getStateByEvent(event, withParams);
    }

    if (state && this.getLangIdAtStateCondition(state)) {
      const startIndex: number = state[2] && state[2] === '/' ? 3 : 2;

      return state.slice(startIndex);
    }

    return state;
  }

  private getLangIdAtStateCondition(state: string): boolean {
    return Object.keys(LangIdsInfo).some((locale: Locale) => {
      const slicedText: string = state?.slice(0, 2);
      const langId: LangId = LangIdsInfo[locale];

      return slicedText === langId && (!state[2] || state[2] === '/');
    });
  }

  private getStateByPure(): string {
    return RoutesHandlerService.getStateByUrl(this.router?.url, true);
  }

  private static getStateWithoutQueryParams(state: string): string {
    const splitList: string[] = state?.split('?');

    return (splitList && splitList[0]) || state;
  }

  private isCompanyPage(state: string = null, event: RouterEvent = null): boolean {
    state = this.getHandledState(state, event);

    return !!state?.match(companyPageRegExp);
  }

  private static getStateByUrl(url: string, withParams: boolean = false): string {
    const currentUrl: string = url;
    const stateUrl: string = withParams ? currentUrl : currentUrl?.split('?')[0];

    return stateUrl?.slice(1) || '';
  }
}
