import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { LocalizationService } from './localization';
import { AuthService } from './auth.service';
import { Locale } from '../models';
import { Localization } from '../classes';

@Injectable({ providedIn: 'root' })
export class LangResolver implements Resolve<string | null> {
  constructor(
    private localizationService: LocalizationService,
    private router: Router,
    private authService: AuthService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string | null {
    const lang: Locale = this.localizationService.getCurrentLocale(
      this.authService.getIsUserLoggedIn()
    );

    if (!Localization.doesRouteIncludeLang(route, lang)) {
      // If lang param is not present, add it to the route
      const fullPath: string = Localization.getFullPathByState(state, lang);

      void this.router.navigateByUrl(fullPath);
    }

    return lang;
  }
}
