<div class="menu">
  <div *ngIf="!forProfileCard" (click)="csvExport.emit()" class="item">
    {{ "EXPORT.EXPORT_TYPE_SELECTION_MENU.CSV" | translate }}
  </div>

  <div *ngIf="forProfileCard" (click)="cvExport.emit()" class="item">
    {{ "EXPORT.EXPORT_TYPE_SELECTION_MENU.CV" | translate }}
  </div>

  <div (click)="cleverStaffExport.emit()" class="item">
    {{ "EXPORT.EXPORT_TYPE_SELECTION_MENU.CLEVER_STAFF" | translate }}
  </div>
</div>