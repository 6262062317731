import { Component, Input } from '@angular/core';
import { CheckBoxData } from '../../../../shared/models';

@Component({
  selector: 'app-locked-checkbox',
  templateUrl: './locked-checkbox.component.html',
  styleUrls: ['./locked-checkbox.component.scss']
})
export class LockedCheckboxComponent {
  @Input() data: CheckBoxData;
}
