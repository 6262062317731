import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService, ErrorService, LocalizationService } from '../services';
import { Locale } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private router: Router,
    private localizationService: LocalizationService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.errorService.resetLastErrorMessage();

    const isUserLoggedIn: boolean = this.authService.getIsUserLoggedIn();

    if (isUserLoggedIn) {
      return true;
    } else {
      const lang: Locale = this.localizationService.getCurrentLocale(
        this.authService.getIsUserLoggedIn()
      );
      this.router.navigateByUrl(`/${lang}`).then(() => {});

      return false;
    }
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(next, state);
  }
}
