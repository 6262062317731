// - & | ! ( ) { } [ ] ^ ' " ~ * ? : \
export const bracketsRegExp = /[\\\\"\\(\\)]/g;
export const parenthesisRegExp = /[\\\\(\\)]/g;
export const SearchRegExpExcludedSymbols = /[&\\!'\\\\"\\|\\(\\)\\{\\}\[\]\\^\\~\\*\\?\:]/g;

export enum SearchFilterType {
  INPUT = 'input',
  CHECKBOX = 'checkbox',
  RANGE_SLIDER = 'rangeSlider',
  CONTACTS = 'contacts',
  REVIEWED = 'reviewed'
}

export enum QueryHelpers {
  MATCH_ALL_OPERATOR = 'AND',
  MATCH_ANY_OPERATOR = 'OR',
  START_VALUE_WRAPPER = '(',
  END_VALUE_WRAPPER = ')',
  QUOTE = '"',
  SPACE = ' ',
  START_INDEX_WRAPPER = '[',
  END_INDEX_WRAPPER = ']',
  EXCLUDED_OPERATOR = 'NOT',
  SEMICOLON = ':',
  PARAM_INDEX = '%PARAM_INDEX%'
}
