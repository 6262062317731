import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import {
  ConditionInfo,
  EditFolderModalData,
  Folder,
  FolderFormValue,
  FoldersStatusListInfo,
  FolderStatus,
  ModalInfo
} from '../../../../../shared/models';
import {
  FolderAccessType,
  FolderStatusComponentType,
  FolderStatusIdsPositionInfo,
  StatusesInfoFilterKey
} from '../../../../../shared/types';
import { DragAndDropHandlerService } from '../../../../../shared/services';
import { InputMaxLengthEnum } from '../../../../../shared/enums';
import { FolderHandlerUtilities, FolderStatusesService } from '../../../services';
import { FolderStatusesUtilities } from '../../../services/folders/folder-statuses.utilities';

@Component({
  selector: 'app-edit-folder-modal',
  templateUrl: './edit-folder-modal.component.html',
  styleUrls: ['./edit-folder-modal.component.scss']
})
export class EditFolderModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<EditFolderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditFolderModalData,
    private statusesService: FolderStatusesService,
    private dragAndDropHandler: DragAndDropHandlerService
  ) {}

  readonly folderNameMaxlength = InputMaxLengthEnum.folderName;
  readonly folderDescMaxLength = InputMaxLengthEnum.folderDesc;

  info: ModalInfo = {
    type: 'simple-modal',
    extraCssClass: 'edit-folder-modal',
    closeModalBtnId: 'close-edit-folder-name-m',
    static: {
      height: true
    },
    header: {
      withForm: true
    },
    actions: {
      cancel: {
        id: 'm-cancel-create-folder--btn'
      },
      submit: {
        id: 'm-create-folder--btn',
        extraClass: 'bright'
      },
      considerDataChange: true
    }
  };

  title: string;
  statusType: FolderStatusComponentType;
  submitBtnText: string;

  initialFormValue: any;
  form: UntypedFormGroup;

  private unsubscribe$ = new Subject<void>();

  get name(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  get description(): UntypedFormControl {
    return this.form.get('description') as UntypedFormControl;
  }

  get accessType(): UntypedFormControl {
    return this.form.get('accessType') as UntypedFormControl;
  }

  get selectedStatuses(): UntypedFormArray {
    return this.form.get('selectedStatuses') as UntypedFormArray;
  }

  get availableStatuses(): UntypedFormArray {
    return this.form.get('availableStatuses') as UntypedFormArray;
  }

  get formUnchangedInfo(): ConditionInfo {
    const condition: boolean =
      JSON.stringify(this.form.value) === JSON.stringify(this.initialFormValue);

    return { condition };
  }

  ngOnInit(): void {
    this.setModalData();
    this.initForm();
    this.handleFormValueChanges();
  }

  closeModal(folder: Folder = null): void {
    this.dialogRef.close(folder);
  }

  onSubmit(): void {
    const folderFormValue: FolderFormValue = this.form?.value;
    const newFolder: Folder = FolderHandlerUtilities.generateNewFolderFromFormValue(
      folderFormValue,
      this.data?.folder
    );

    if (newFolder) {
      this.closeModal(newFolder);
    }
  }

  changeAccessType(type: FolderAccessType): void {
    this.accessType.patchValue(type);
  }

  drop(event: CdkDragDrop<FolderStatus[]>): void {
    this.dragAndDropHandler.finishDropByMaterialMethods(event);

    const defaultStatusesLastPosition: number = this.selectedStatuses.value?.length;
    const filterType: StatusesInfoFilterKey = this.getStatusesInfoFilterKey();
    const selectedStatuses: FolderStatus[] = this.selectedStatuses?.value || [];
    const availableStatuses: FolderStatus[] = this.availableStatuses?.value || [];

    this.dragAndDropHandler.updateFilteredStatusesListAfterDrop(selectedStatuses, filterType);
    this.dragAndDropHandler.updateRestStatusesListAfterDrop(
      availableStatuses,
      filterType,
      defaultStatusesLastPosition
    );

    const fullStatuses: FolderStatus[] = selectedStatuses.concat(availableStatuses);

    this.selectedStatuses.clear();
    this.availableStatuses.clear();

    this.setStatusesToForm(fullStatuses);
  }

  private getStatusesInfoFilterKey(): StatusesInfoFilterKey {
    return this.data?.type === 'edit' ? 'active' : 'defaultStatus';
  }

  private setModalData(): void {
    if (this.data?.type === 'edit') {
      this.title = 'FOLDERS.EDIT_FOLDER_MODAL.TITLE';
      this.statusType = 'click-all';
      this.submitBtnText = 'GENERAL.EDIT';
    } else if (this.data?.type === 'create') {
      this.title = 'FOLDERS.CREATE_FOLDER_MODAL.TITLE';
      this.statusType = 'default-change-only';
      this.submitBtnText = 'GENERAL.CREATE';
    }

    this.setModalInfoOnInitData();
  }

  private setModalInfoOnInitData(): void {
    this.info.static.title = !!this.title;
    this.info.header.title = this.title;
    this.info.actions.submit.text = this.submitBtnText;
  }

  private setStatusesToForm(statuses: FolderStatus[]): void {
    if (statuses) {
      const filterKey: StatusesInfoFilterKey =
        this.data?.type === 'edit' ? 'active' : 'defaultStatus';
      const statusesListInfo: FoldersStatusListInfo = FolderStatusesUtilities.getStatusesListInfo(
        statuses,
        filterKey
      );
      const { filteredStatuses, restStatuses } = statusesListInfo;

      filteredStatuses.forEach((status: FolderStatus) => {
        this.selectedStatuses.push(new UntypedFormControl(status));
      });

      restStatuses.forEach((status: FolderStatus) => {
        this.availableStatuses.push(new UntypedFormControl(status));
      });

      this.handleStatusesLastDefault(
        this.selectedStatuses?.value,
        this.availableStatuses?.value,
        filterKey
      );
    }
  }

  private initForm(): void {
    let statuses: FolderStatus[];

    if (this.data?.type === 'edit') {
      this.initFormForEdit();

      statuses = this.getHandledStatusesForEdit();
    } else if (this.data?.type === 'create') {
      this.initFormForCreate();

      statuses = this.data?.statuses;
    }

    this.watchForFolderName();
    this.setStatusesToForm(statuses);

    this.initialFormValue = { ...this.form.value };
  }

  private initFormForEdit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.data?.folder?.name, Validators.required),
      description: new UntypedFormControl(this.data?.folder?.description),
      accessType: new UntypedFormControl(this.data?.folder?.privateAccess ? 'private' : 'team'),
      selectedStatuses: new UntypedFormArray([]),
      availableStatuses: new UntypedFormArray([])
    });
  }

  private initFormForCreate(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl(''),
      accessType: new UntypedFormControl('private'),
      selectedStatuses: new UntypedFormArray([]),
      availableStatuses: new UntypedFormArray([])
    });
  }

  private setSubmitBtnDisabledCondition(): void {
    this.info.actions.submit.disabled = this.form.invalid;
  }

  private handleFormValueChanges(): void {
    this.setSubmitBtnDisabledCondition();

    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.setSubmitBtnDisabledCondition();
    });
  }

  private getHandledStatusesForEdit(): FolderStatus[] {
    const systemStatuses: FolderStatus[] = this.getSystemStatuses();
    const statusIds: string[] = [...this.data?.folder?.statusIds];
    const statusIdsInfo: FolderStatusIdsPositionInfo =
      this.statusesService.getStatusIdsPositionInfo(statusIds);

    systemStatuses.forEach((systemStatus: FolderStatus, index: number) => {
      if (statusIds.some((itemId: string) => systemStatus.id === itemId)) {
        const active: boolean = true;
        const position: number = statusIdsInfo[systemStatus.id];

        systemStatuses[index] = { ...systemStatus, active, position };
      }
    });

    return systemStatuses;
  }

  private getSystemStatuses(): FolderStatus[] {
    if (!this.data?.statuses) {
      return [];
    }

    return this.data?.statuses.map((item: FolderStatus) => {
      return { ...item };
    });
  }

  private handleStatusesLastDefault(
    selectedStatuses: FolderStatus[],
    availableStatuses: FolderStatus[],
    filterKey: StatusesInfoFilterKey
  ): void {
    if (selectedStatuses?.length === 1) {
      selectedStatuses?.forEach((statusItem: FolderStatus, index: number) => {
        selectedStatuses[index].lastDefault = statusItem[filterKey];
      });
    } else {
      selectedStatuses?.forEach((statusItem: FolderStatus, index: number) => {
        selectedStatuses[index].lastDefault = false;
      });

      availableStatuses?.forEach((statusItem: FolderStatus, index: number) => {
        availableStatuses[index].lastDefault = false;
      });
    }
  }

  private watchForFolderName(): void {
    this.name.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value: string) => {
      const nameWithBigFirstLetter: string =
        EditFolderModalComponent.getNameWithBigFirstLetter(value);

      if (nameWithBigFirstLetter) {
        this.name.patchValue(nameWithBigFirstLetter, { emitEvent: false });
      }
    });
  }

  private static getNameWithBigFirstLetter(value: string): string {
    if (value) {
      const firstLetter: string = value[0].toUpperCase();
      const restText: string = value.slice(1);

      return `${firstLetter}${restText}`;
    }

    return '';
  }

  ngOnDestroy(): void {
    this.data = null;

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
