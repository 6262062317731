import { ContactEnum, ContactFilterItem } from '../../../../../shared/models';
import { ContactsFilterInfo } from '../../../../../shared/classes';

export class ContactsFilterData {
  static getInitContactsFilterInfo(
    withContact: ContactFilterItem = null,
    noContacts: ContactFilterItem = null,
    allContacts: ContactFilterItem = null
  ): ContactsFilterInfo {
    if (!withContact) {
      withContact = {
        label: 'FILTER.CONTACTS.ANY_CONTACTS',
        checked: true,
        contactsTypeList: [ContactEnum?.EMAIL, ContactEnum?.PHONE],
        specificFacetType: 'contacts_withAny',
        [ContactEnum?.EMAIL]: {
          label: 'CONTACTS.WITH_LOWERCASE.EMAIL',
          specificFacetType: 'contacts_withEmail',
          labelFull: 'CONTACTS.EMAIL'
        },
        [ContactEnum?.PHONE]: {
          label: 'CONTACTS.WITH_LOWERCASE.PHONE',
          specificFacetType: 'contacts_withPhone',
          labelFull: 'CONTACTS.PHONE'
        }
      };
    }

    if (!noContacts) {
      noContacts = {
        label: 'FILTER.CONTACTS.NO_CONTACTS',
        specificFacetType: 'contacts_withoutAny',
        valueForParsing: 'false'
      };
    }

    if (!allContacts) {
      allContacts = {
        label: 'FILTER.CONTACTS.NO_FILTERING_BY_CONTACTS',
        facetType: 'contacts',
        specificFacetType: 'contacts_withOrWithout',
        valueForParsing: 'any'
      };
    }

    return new ContactsFilterInfo(withContact, noContacts, allContacts);
  }
}
