import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestService } from './rest.service';
import {
  AddProfileInFoldersRequestData,
  AddProfileListInFoldersRequestData,
  ApiResponse,
  ApiResponseSingle,
  CandidateFolder,
  CandidateFoldersInfo,
  Folder,
  FolderMassActionDto,
  FolderParams,
  FoldersCountInfo,
  FolderStatus,
  FolderStatusesStatisticResponse
} from '../../models';
import { FolderConstructor, FolderStatusConstructor } from '../../classes';
import { FolderStatusesService } from '../../../modules/system-shared/services';

@Injectable({
  providedIn: 'root'
})
export class FolderService {
  constructor(
    private restService: RestService,
    private statusesService: FolderStatusesService
  ) {}

  // API methods:

  getAllStatuses(): Observable<FolderStatus[]> {
    return this.restService
      .Folder()
      .getAllStatuses()
      .pipe(
        map((data: ApiResponseSingle<FolderStatus[]>) => {
          return this.statusesService.getStatusesFromApiResponseData(data);
        })
      );
  }

  addStatus(status: FolderStatus): Observable<FolderStatus[]> {
    return this.restService
      .Folder()
      .addStatus(status)
      .pipe(
        map((data: ApiResponseSingle<FolderStatus[]>) => {
          return this.statusesService.getStatusesFromApiResponseData(data);
        })
      );
  }

  deleteStatus(id: string): Observable<FolderStatus[]> {
    return this.restService
      .Folder()
      .deleteStatus(id)
      .pipe(
        map((data: ApiResponseSingle<FolderStatus[]>) => {
          return this.statusesService.getStatusesFromApiResponseData(data);
        })
      );
  }

  editStatuses(statuses: FolderStatus[] = []): Observable<FolderStatus[]> {
    const cleanStatuses: FolderStatus[] = statuses.map((status: FolderStatus) => {
      return FolderStatusConstructor.toInstance(status);
    });

    return this.restService
      .Folder()
      .editStatuses(cleanStatuses)
      .pipe(
        map((data: ApiResponseSingle<FolderStatus[]>) => {
          return this.statusesService.getStatusesFromApiResponseData(data);
        })
      );
  }

  getCount(): Observable<FoldersCountInfo> {
    return this.restService
      .Folder()
      .getCount()
      .pipe(
        map((data: ApiResponseSingle<FoldersCountInfo>) => {
          const info: FoldersCountInfo = RestService.getData(data);
          const checkedInfo: FoldersCountInfo = {
            folders: info?.folders || 0,
            statuses: info?.statuses || 0
          };

          return checkedInfo;
        })
      );
  }

  getAllFolders(withCandidates: boolean = false, docIds: string[] = []): Observable<Folder[]> {
    const profileIdsQuery: string = docIds?.length ? docIds?.join(',') : '';

    return this.restService
      .Folder()
      .getAllFolders(withCandidates, profileIdsQuery)
      .pipe(map((data: ApiResponseSingle<Folder[]>) => RestService.getData(data) || []));
  }

  addFolder(folder: Folder): Observable<Folder> {
    return this.restService
      .Folder()
      .addFolder(folder)
      .pipe(map((data: ApiResponseSingle<Folder>) => RestService.getData(data)));
  }

  deleteFolder(id: string): Observable<Folder[]> {
    return this.restService
      .Folder()
      .deleteFolder(id)
      .pipe(map((data: ApiResponseSingle<Folder[]>) => RestService.getData(data) || []));
  }

  editFolder(folder: Folder): Observable<Folder> {
    const cleanFolder: Folder = FolderConstructor.toInstance(folder);

    FolderService.handleFolderRequestInfoBeforeSend(cleanFolder);

    return this.restService
      .Folder()
      .editFolder(cleanFolder)
      .pipe(map((data: ApiResponseSingle<Folder>) => this.handleFolder(data)));
  }

  getFolder(folder: FolderParams): Observable<Folder> {
    FolderService.handleFolderRequestInfoBeforeSend(folder);

    return this.restService
      .Folder()
      .getFolder(folder)
      .pipe(
        map((data: ApiResponseSingle<Folder>) => {
          return data ? this.handleFolder(data) : null;
        })
      );
  }

  addProfileInFolders(data: AddProfileInFoldersRequestData): Observable<CandidateFolder[]> {
    return this.restService
      .Folder()
      .addProfileInFolders(data)
      .pipe(
        map((data: ApiResponseSingle<CandidateFoldersInfo>) => {
          return FolderService.getCandidateFolderFromInfo(data);
        })
      );
  }

  addProfileListInFolder(
    queryString: string,
    data: AddProfileListInFoldersRequestData
  ): Observable<null> {
    return this.restService
      .Folder()
      .addProfileListInFolder(queryString, data)
      .pipe(
        map((data: ApiResponseSingle<null>) => {
          return RestService.getData(data);
        })
      );
  }

  removeProfilesFromFolder(data: FolderMassActionDto): Observable<ApiResponse<null>> {
    return this.restService.Folder().removeProfilesFromFolder(data);
  }

  removeProfileFromFolders(docId: string, folderIds: string[] = []): Observable<CandidateFolder[]> {
    return this.restService
      .Folder()
      .removeProfileFromFolders(docId, folderIds)
      .pipe(
        map((data: ApiResponseSingle<CandidateFoldersInfo>) => {
          return FolderService.getCandidateFolderFromInfo(data);
        })
      );
  }

  statusStatistics(folderIds: string[] = []): Observable<FolderStatusesStatisticResponse> {
    const folderIdsQuery: string = folderIds?.join(', ');

    return this.restService
      .Folder()
      .statusStatistics(folderIdsQuery)
      .pipe(
        map((data: ApiResponseSingle<FolderStatusesStatisticResponse>) => RestService.getData(data))
      );
  }

  multipleCopy(data: FolderMassActionDto): Observable<CandidateFoldersInfo> {
    return this.restService
      .Folder()
      .multipleCopy(data)
      .pipe(map((data: ApiResponseSingle<CandidateFoldersInfo>) => RestService.getData(data)));
  }

  multipleMove(data: FolderMassActionDto): Observable<CandidateFoldersInfo> {
    return this.restService
      .Folder()
      .multipleMove(data)
      .pipe(map((data: ApiResponseSingle<CandidateFoldersInfo>) => RestService.getData(data)));
  }

  changeStatus(data: FolderMassActionDto): Observable<CandidateFoldersInfo> {
    return this.restService
      .Folder()
      .changeStatus(data)
      .pipe(map((data: ApiResponseSingle<CandidateFoldersInfo>) => RestService.getData(data)));
  }

  // Request handlers:

  private handleFolder(data: ApiResponseSingle<Folder>): Folder {
    const newFolder: Folder = RestService.getData(data);
    const statusesList: FolderStatus[] = this.statusesService.getFolderStatusesListFromInfo({
      info: newFolder?.statuses
    });

    this.statusesService.handleStatuses(statusesList);

    return newFolder;
  }

  private static handleFolderRequestInfoBeforeSend(folder: Folder): void {
    if (!folder.page && folder.page !== 0) {
      folder.page = 0;
    }

    if (!folder.count) {
      folder.count = 30;
    }
  }

  // Rest common methods:

  private static getCandidateFolderFromInfo(
    data: ApiResponseSingle<CandidateFoldersInfo>
  ): CandidateFolder[] {
    const info: CandidateFoldersInfo = RestService.getData(data);

    if (info) {
      return Object.values(info).find((folders: CandidateFolder[]) => !!folders);
    }

    return [];
  }
}
