import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-export-selector-menu',
  templateUrl: './export-selector-menu.component.html',
  styleUrls: ['./export-selector-menu.component.scss']
})
export class ExportSelectorMenuComponent {
  @Input() forProfileCard = false;

  @Output() csvExport = new EventEmitter();
  @Output() cleverStaffExport = new EventEmitter();
  @Output() cvExport = new EventEmitter();
}
